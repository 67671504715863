/* eslint-disable no-underscore-dangle */
// TODO(jason): Add JSDoc for this

import { Logger } from "./logger";
import { Maybe } from "./maybe";

// This is a Result monad
export class Result<T, E> {
    private value: T | E;

    private _isError: boolean;

    private constructor(value: T | E, isError: boolean) {
        this.value = value;
        this._isError = isError;
    }

    static ok<T, E>(value: T): Result<T, E> {
        return new Result<T, E>(value, false);
    }

    static error<T, E>(error: E): Result<T, E> {
        return new Result<T, E>(error, true);
    }

    public get isError() {
        return this._isError;
    }

    public get isOk() {
        return !this._isError;
    }

    private static isError<T, E>(res: Result<T, E>, value: T | E): value is E {
        return res.isError;
    }

    private static isOk<T, E>(res: Result<T, E>, value: T | E): value is T {
        return res.isOk;
    }

    public map<R>(fn: (val: T) => R): Result<R, E> {
        if (this.isError) return new Result<R, E>(this.value as E, true);
        return new Result<R, E>(fn(this.value as T), false);
    }

    public mapErr<R>(fn: (val: E) => R): Maybe<R> {
        if (Result.isOk(this, this.value)) return Maybe.none();
        return Maybe.some(fn(this.value));
    }

    public flatMap<R>(fn: (val: T) => Result<R, E>): Result<R, E> {
        if (this.isError) return new Result<R, E>(this.value as E, true);
        return fn(this.value as T);
    }

    public getOrElse(defaultValue: T): T {
        return this.isError ? defaultValue : (this.value as T);
    }

    public orElse(defaultValue: Result<T, E>): Result<T, E> {
        return this.isError ? defaultValue : this;
    }

    public required(errorMessage: string): T {
        if (this.isError) {
            Logger.error(errorMessage);
            throw this.value;
        }
        return this.value as T;
    }
}
