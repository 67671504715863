import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAccount } from 'wagmi';
import { Logger } from '../utils/logger';
import { getUserKarakBalances } from '../utils/userBalances';

interface UserBalances {
  ethBalance: string;
  usdcBalance: string;
}

type BalanceType = 'karak';

export type UserBalancesContextValue = {
  karakBalances: UserBalances;
  showOnboarding: boolean;
  refreshBalances(type: BalanceType): void;
};

const UserBalancesContext = React.createContext<UserBalancesContextValue>({
  karakBalances: { ethBalance: '0', usdcBalance: '0' },
  showOnboarding: false,
  refreshBalances() {},
});
  
export function useUserBalances() {
  return useContext(UserBalancesContext);
}

export const UserBalancesProvider: React.FC<any> = ({ children }) => {
  const [refreshBeats, setRefreshBeats] = useState({
    karak: BigInt(0),
  });
  const [karakBalances, setKarakBalances] = useState({
    ethBalance: '0',
    usdcBalance: '0',
  });
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { address } = useAccount();
    
  useEffect(() => {
    const fetchKarakBalances = async () => {
      if (!address) return;
      try {
        const { ethBalance, usdcBalance } = await getUserKarakBalances(address);
        if (ethBalance === '0' && usdcBalance === '0') setShowOnboarding(true);
        setKarakBalances({ ethBalance, usdcBalance });
      } catch (error) {
        Logger.error('Error fetching Karak balances:', error);
      }
    }
    
    fetchKarakBalances();
  }, [address, refreshBeats.karak]);
  
  const refresh = useRef((type: BalanceType) => {
    setRefreshBeats(prevState => ({
      ...prevState,
      [type]: prevState[type] + BigInt(1),
    }));
  });
  
  const value = useMemo(
    () => ({
      karakBalances,
      showOnboarding,
      refreshBalances: (type: BalanceType) => refresh.current(type),
    }),
    [karakBalances, showOnboarding]
  );
  
  return (
    <UserBalancesContext.Provider value={value}>
      {children}
    </UserBalancesContext.Provider>
  );
};
