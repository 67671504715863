import React, { useMemo } from 'react';
import styled from 'styled-components';
import Decimal from 'decimal.js';
import moment from 'moment';
import numeral from 'numeral';
import { Fraction } from '@risk-harbor/subsea-sdk';
import { ToolTip } from '../ToolTip/ToolTip';
import { PoolDetails } from '../../constants/constants';
import { usePoolPageContext } from '../../contexts/PoolPage';
import { bannerDetailsFallback } from '../../utils/cmsfallbacks';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: normal;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

const formatPayoutRatio = (
  ratio: Fraction,
  protDecimals: number,
  undrDecimals: number
): string => {
  const value = new Decimal(10)
    .pow(protDecimals)
    .mul(new Decimal(ratio.numerator.toString()))
    .div(new Decimal(ratio.denominator.toString()))
    .div(new Decimal(10).pow(undrDecimals));
  return numeral(value).format(value.modulo(1).eq(0) ? '0' : '0.00');
};

export const PoolDetailsGrid: React.FC = () => {
  const protectedTokenText = 'PROTECTED TOKEN';
  const underlyingTokenText = 'UNDERLYING TOKEN';
  const underwrtTokenText = 'UNDERWRITING TOKEN';
  const defaultRtText = 'DEFAULT RATIO';
  const payoutRtText = 'PAYOUT RATIO';
  const expDateText = 'EXPIRATION DATE';

  const poolPageData = usePoolPageContext();
  const [onChainData, cmsData] = useMemo(() => {
    return [
      poolPageData.map((d) => d.pool.onchain),
      poolPageData.flatMap((d) => d.pool.cms),
    ];
  }, [poolPageData]);

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          {protectedTokenText}
          <ToolTip tooltipText={PoolDetails.PT} />
        </Text>
        <SmallText>
          {onChainData
            .map((d) =>
              d.config.remoteProtectedToken.symbol
            )
            .string()}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underlyingTokenText}
          <ToolTip tooltipText={PoolDetails.ULT} />
        </Text>
        <SmallText>
          {cmsData
            .map((d) => d.underlyingTokensDisplay.join('\n'))
            .getOrElse(bannerDetailsFallback)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underwrtTokenText}
          <ToolTip tooltipText={PoolDetails.UWT} />
        </Text>
        <SmallText>
          {onChainData
            .map((d) => d.vault.base.config.underwritingToken.symbol)
            .string()}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {defaultRtText}
          <ToolTip tooltipText={PoolDetails.DR} />
        </Text>
        <SmallText>
          {cmsData
            .map((d) => d.defaultRatioDisplay.join('\n'))
            .getOrElse(bannerDetailsFallback)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {payoutRtText}
          <ToolTip tooltipText={PoolDetails.PR} />
        </Text>
        <SmallText>
          1 Protected :{' '}
          {onChainData
            .map((d) =>
              formatPayoutRatio(
                d.config.payoutRatio,
                d.config.remoteProtectedToken.decimals,
                d.vault.base.config.underwritingToken.decimals
              )
            )
            .string()}{' '}
          Underwriting
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {expDateText}
          <ToolTip tooltipText={PoolDetails.EXP} />
        </Text>
        <SmallText>
          {onChainData
            .map((d) => {
              const expirationMs = d.vault.base.config.expiration * 1e3;
              const expirationDate = new Date(expirationMs);
              return `${moment(expirationDate.toUTCString()).format(
                'MMM. D, YYYY'
              )}`;
            })
            .string()}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
