import React, { useState } from 'react';
import styled from 'styled-components';
import { OrderButton } from './OrderButton';
import { useVaultPageContext } from '../../contexts/VaultPage';
import { Time } from '../../utils/time';
import { mapRawAmountToUi } from '../../utils/helpers';
import { useWithdraw } from '../../actions/withdraw';
import { Spinner } from '@chakra-ui/react';
import { useNetwork, useSwitchNetwork } from 'wagmi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-purple);
  border: 1px solid var(--border-purple);
  border-radius: 8px;
  margin-top: 0.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p2-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
`;

interface Props {
  onConnect: () => void;
}

export const Withdraw: React.FC<Props> = ({ onConnect }) => {
  const [loading, setLoading] = useState(false);
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const vaultPageContext = useVaultPageContext();
  const vault = vaultPageContext.map((d) => d.vault.onchain);
  const isExpired = vault
    .map((v) =>
      Time.fromEvmTimestamp(v.base.config.expiration).isBefore(Time.now)
    )
    .boolean();
  const vaultChainId = vault.map((v) => v.chain.id).getOrElse(Number);

  const undrToken = vault.map((v) => v.base.config.underwritingToken);
  const undrTokenDecimals = undrToken.map((t) => t.decimals);
  const undrTokenSymbol = undrToken.map((t) => t.symbol);

  const withdraw = useWithdraw(vault);
  const withdrawingUndrAmount = withdraw
    .quote()
    .map((q) => q.output.underwritingTokens);

  return (
    <Container>
      {isExpired ? (
        <>
          <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
            <Text>UNDERWRITING TOKENS</Text>
            <AmountText>
              {`${withdrawingUndrAmount
                .zip(undrTokenDecimals)
                .map(([amt, dec]) => mapRawAmountToUi(amt.toString(), dec))
                .string()} ${undrTokenSymbol.string()}`.trim()}
            </AmountText>
          </AmountBox>
          <OrderButton
            onConnect={onConnect}
            disabled={loading}
            vaultPaused={vault
              .map((v) => v.base.state.isPaused)
              .getOrElse(Boolean)}
            actionType="Withdraw"
            onClick={async () => {
              setLoading(true);
              if (chain && chain.id !== vaultChainId) {
                await switchNetworkAsync?.(vaultChainId);
              }
              await withdraw.perform();
              setLoading(false);
            }}
          >
            {loading ? <Spinner /> : 'Withdraw'}
          </OrderButton>
        </>
      ) : (
        <>
          <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
            <Text>
              Withdrawals cannot be processed at this time. Withdrawals will be
              available when the vault expires.
            </Text>
          </AmountBox>
          <OrderButton onConnect={onConnect} actionType="Withdraw" disabled />
        </>
      )}
    </Container>
  );
};
