export const poolRiskScoreFallback = () => {
  return 'N/A';
};

export const vaultDescriptionFallback = () => {
  return 'Vault description is currently not available due to missing CMS data.';
};

export const poolDescriptionFallback = () => {
  return 'Pool description is currently not available due to missing CMS data.';
};

export const underwriterRisksFallback = () => {
  return 'Underwriter risks description is currently not available due to missing CMS data.';
};

export const policyholderRisksFallback = () => {
  return 'Policyholder risks description is currently not available due to missing CMS data.';
};

export const bannerDetailsFallback = () => {
  return 'N/A';
};

export const nameFallback = () => {
  return 'NOT AVAILABLE';
};
