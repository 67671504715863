import { Address } from '@risk-harbor/subsea-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCMSData } from './CMSData';
import { useOnChainData, VaultWithChain } from './OnChainData';
import { CMSVault } from '../services';
import { getAddressFromString } from '../utils/address';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { parseChainFromNetworkStr } from '../utils/network';
import { SubseaRoutes } from '../utils/routes';
import { Chain } from 'wagmi';

export type VaultPageContextValue = {
  vault: {
    onchain: VaultWithChain;
    cms: Maybe<CMSVault>;
  };
};

export const VaultPageContext = createContext<Maybe<VaultPageContextValue>>(
  Maybe.none()
);

function isVaultPath(path: string): boolean {
  return SubseaRoutes.vaultBaseRegex().test(path);
}

function extractChainAndAddress(
  path: string
): Maybe<{ chain: Chain; address: Address }> {
  const match = SubseaRoutes.vaultExactRegex().exec(path);
  if (match === null || match.length < 2) {
    return Maybe.none();
  }

  try {
    return Maybe.some({
      chain: parseChainFromNetworkStr(match[1]),
      address: getAddressFromString(match[2]),
    });
  } catch (err) {
    Logger.error(
      err,
      `Error occurred while parsing chain and address out of ${path}`
    );
    return Maybe.none();
  }
}

export function useVaultPageContext() {
  return useContext(VaultPageContext);
}

export const VaultPageContextProvider: React.FC<any> = ({ children }) => {
  const onChainData = useOnChainData();
  const cmsData = useCMSData();
  const [value, setValue] = useState<Maybe<VaultPageContextValue>>(
    Maybe.none()
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const vaultPathMatching = isVaultPath(location.pathname);

    const cleanup = () => {
      setValue(Maybe.none());
    };

    if (!vaultPathMatching) {
      setValue(Maybe.none());
      return cleanup;
    }

    const chainAndAddr = extractChainAndAddress(location.pathname);
    const maybeVault = chainAndAddr.flatMap(({ chain, address }) =>
      onChainData.flatMap((d) =>
        Maybe.from(
          d.vaults.find(
            (v) =>
              getAddressFromString(v.addr) === address &&
              v.chain.id === chain.id
          )
        )
      )
    );

    if (maybeVault.isNone()) {
      setValue(Maybe.none());
      navigate('/not-found');
      return cleanup;
    }

    const vault = maybeVault.required();

    const cmsVault = cmsData.flatMap((d) =>
      Maybe.from(
        d.vaults.find(
          (v) =>
            getAddressFromString(v.address) ===
              getAddressFromString(vault.addr) && v.chain.id === vault.chain.id
        )
      )
    );

    setValue(
      Maybe.some({
        vault: {
          onchain: vault,
          cms: cmsVault,
        },
      })
    );

    return cleanup;
  }, [cmsData, location.pathname, navigate, onChainData]);

  return (
    <VaultPageContext.Provider value={value}>
      {children}
    </VaultPageContext.Provider>
  );
};
