import React, { useMemo } from 'react';
import { InfoCard } from '../components/Info/InfoCard';
import { VaultTable } from '../components/Tables/VaultTable';
import coin from '../assets/color-logos/purple-coin.svg';
import { useOnChainData } from '../contexts';
import { useCMSData } from '../contexts/CMSData';
import { Transformer } from '../utils/transformer';

export const ProvideProtection: React.FC = () => {
  const onchain = useOnChainData();
  const cmsData = useCMSData();
  const vaultListItems = useMemo(
    () =>
      Transformer.vaultsToListViews(
        onchain.map((data) => data.vaults).getOrElse(Array),
        cmsData.map((data) => data.vaults).getOrElse(Array)
      ),
    [cmsData, onchain]
  );

  return (
    <>
      <InfoCard imageSrc={coin} title="Provide Protection" />
      <VaultTable vaults={vaultListItems} />
    </>
  );
};
