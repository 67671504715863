import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Stack,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import { useTable } from 'react-table';
import emptyTokenIcon from '../../assets/empty-token.png';
import { PoolListItemView } from '../../types/types';
import { FilterStatus } from '../../constants/constants';
import { SearchNotFound } from '..//Search/SearchNotFound';
import { SearchBar } from '../Search/SearchBar';
import { getTokenSymbol } from '../../utils/helpers';
import { SubseaRoutes } from '../../utils/routes';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-purple);
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const Image = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const TokenSymbol = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: var(--text-primary-white);
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VFlexbox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const PageSelector = styled.div`
  float: right;
  color: var(--text-primary-white);
  font-weight: 600;
  margin-top: 1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media only screen and (max-width: 960px) {
    float: left;
  }
`;

const PageMover = styled.span<{ disabled: boolean }>`
  padding: 0.625rem;
  color: ${(props) =>
    props.disabled ? `#ffffff50 !important` : `none !important`};
  &:hover {
    cursor: pointer;
  }
`;

const SGrid = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    column-gap: 0rem;
    row-gap: 0.5rem;
  }
`;

interface Props {
  pools: PoolListItemView[];
}

const Token: React.FC<{ value: string }> = ({ value }) => {
  const [tokenIcon, tokenSymbol] = value.split('&');

  return (
    <HFlexbox>
      <Image
        src={tokenIcon}
        alt=""
        onError={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e.target as any).src = emptyTokenIcon;
        }}
      />
      <VFlexbox>
        <TokenSymbol>{tokenSymbol}</TokenSymbol>
      </VFlexbox>
    </HFlexbox>
  );
};

const getStatus = (poolHacked: boolean, poolPaused: boolean) => {
  if (poolHacked) {
    return 'Hacked';
  }
  return poolPaused ? 'Paused' : 'Open';
};

const getPoolSearchInfo = (p: PoolListItemView, filter: string) => {
  return (
    p.name.toLowerCase().includes(filter.toLowerCase()) ||
    p.network.toLowerCase().includes(filter.toLowerCase()) ||
    getStatus(p.hacked, p.paused)
      .toLowerCase()
      .includes(filter.toLowerCase()) ||
    getTokenSymbol(p.protectedToken)
      .toLowerCase()
      .includes(filter.toLowerCase()) ||
    getTokenSymbol(p.underwritingToken)
      .toLowerCase()
      .includes(filter.toLowerCase()) ||
    p.protectedTokenAddress === filter.toLowerCase() ||
    p.underwritingTokenAddress === filter.toLowerCase() ||
    p.expiryDate.toLowerCase().includes(filter.toLowerCase())
  );
};

export const PoolTable: React.FC<Props> = ({ pools }) => {
  const navigate = useNavigate();
  const MAX_POOLS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filteredPools, setFilteredPools] = useState<PoolListItemView[]>([]);
  const [chipStatus, setChipStatus] = useState<FilterStatus>(FilterStatus.OPEN);
  const [checkedItems, setCheckedItems] = useState([false, false, false]);

  const pageCount = Math.ceil(filteredPools.length / MAX_POOLS_PER_PAGE || 1);

  const data = useMemo(() => {
    const poolsFiltered = pools.filter((p) => {
      if (chipStatus === FilterStatus.OPEN || !checkedItems.includes(true)) {
        return !p.hacked && !p.paused && getPoolSearchInfo(p, filter);
      }
      if (chipStatus === FilterStatus.HACKED) {
        return p.hacked && getPoolSearchInfo(p, filter);
      }
      return p.paused && getPoolSearchInfo(p, filter);
    });
    setFilteredPools(poolsFiltered);
    return poolsFiltered
      .slice(
        (currentPage - 1) * MAX_POOLS_PER_PAGE,
        Math.min(currentPage * MAX_POOLS_PER_PAGE, filteredPools.length)
      )
      .map((pool: PoolListItemView) => ({
        protected: pool.protectedToken,
        underwriting: pool.underwritingToken,
        network: pool.network,
        protocol: pool.name,
        status: getStatus(pool.hacked, pool.paused),
        expiry: pool.expiryDate,
        vaultID: pool.vaultID,
        poolID: pool.poolID,
      }));
  }, [
    pools,
    currentPage,
    filter,
    filteredPools.length,
    chipStatus,
    checkedItems,
  ]);

  const setPage = (selectedPage: number) => {
    if (selectedPage < 1 || selectedPage > pageCount) return;
    setCurrentPage(selectedPage);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'PROTECTED',
        accessor: 'protected',
        Cell: Token,
      },
      {
        Header: 'UNDERWRITING',
        accessor: 'underwriting',
        Cell: Token,
      },
      {
        Header: 'NETWORK',
        accessor: 'network',
      },
      {
        Header: 'PROTOCOL',
        accessor: 'protocol',
      },
      {
        Header: 'STATUS',
        accessor: 'status',
      },
      {
        Header: 'EXPIRY DATE',
        accessor: 'expiry',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns,
      data,
    });

  return (
    <>
      <SGrid>
        <SearchBar onInputChange={setFilter} />
        <Stack direction="row" marginBottom="0.8rem" marginRight="auto">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
              _active={{ filter: 'brightness(85%)' }}
              _focus={{ outline: 'none', boxShadow: 'none' }}
              px="var(--button-px)"
              py="var(--button-py)"
              textColor="white"
              background="var(--components-primary-purple)"
              border="0.5px solid #7e9bd1"
              fontSize="var(--p-font-size)"
            >
              Filter pools
            </MenuButton>
            <MenuList bg="var(--onboarding-color)" border="transparent">
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.OPEN)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[0]}
                  onChange={(e) =>
                    setCheckedItems([e.target.checked, false, false])
                  }
                >
                  Open
                </Checkbox>
              </MenuItem>
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.PAUSED)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[1]}
                  onChange={(e) =>
                    setCheckedItems([false, e.target.checked, false])
                  }
                >
                  Paused
                </Checkbox>
              </MenuItem>
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.HACKED)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[2]}
                  onChange={(e) => {
                    setCheckedItems([false, false, e.target.checked]);
                  }}
                >
                  Hacked
                </Checkbox>
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </SGrid>
      <>
        <Box overflowX="auto">
          <STable variant="unstyled" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index, headers) => {
                    if (headers.length - 1 === index) {
                      return (
                        <STh {...column.getHeaderProps()} key={index}>
                          {column.render('Header')}
                        </STh>
                      );
                    }
                    return (
                      <React.Fragment key={index}>
                        <STh {...column.getHeaderProps()}>
                          {column.render('Header')}
                        </STh>
                        <STh />
                      </React.Fragment>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <STr
                    {...row.getRowProps()}
                    onClick={() => {
                      navigate(
                        SubseaRoutes.pool(
                          row.original.network,
                          row.original.vaultID,
                          row.original.poolID
                        )
                      );
                    }}
                  >
                    {row.cells.map((cell, index, cells) => {
                      if (cells.length - 1 === index) {
                        return (
                          <STd
                            {...cell.getCellProps()}
                            key={index}
                            borderRight="1px solid var(--border-purple)"
                          >
                            {cell.render('Cell')}
                          </STd>
                        );
                      }
                      return (
                        <React.Fragment key={index}>
                          <STd
                            {...cell.getCellProps()}
                            borderLeft={
                              index === 0
                                ? '1px solid var(--border-purple)'
                                : 'none'
                            }
                          >
                            {cell.render('Cell')}
                          </STd>
                          <STd />
                        </React.Fragment>
                      );
                    })}
                  </STr>
                );
              })}
            </Tbody>
          </STable>
          {filteredPools.length === 0 && (
            <SearchNotFound
              message={
                filter === ''
                  ? `There are currently no ${chipStatus} pools.`
                  : 'Sorry, we weren’t able to find results for your search.'
              }
            />
          )}
        </Box>
        {filteredPools.length > 0 && pools.length > MAX_POOLS_PER_PAGE && (
          <Center>
            <PageSelector>
              <PageMover
                disabled={currentPage === 1}
                onClick={() => setPage(currentPage - 1)}
              >
                <ArrowBackIcon boxSize={6} />
              </PageMover>
              Page&nbsp;
              {currentPage} of&nbsp;{pageCount}
              <PageMover
                disabled={currentPage === pageCount}
                onClick={() => setPage(currentPage + 1)}
              >
                <ArrowForwardIcon boxSize={6} />
              </PageMover>
            </PageSelector>
          </Center>
        )}
      </>
    </>
  );
};
