/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Code } from '@chakra-ui/react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const Heading = styled.p`
  font-size: 10rem;
  color: var(--text-primary-white);
  font-weight: bold;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: 8rem;
  }
`;

const SubHeading = styled.p`
  font-size: 3rem;
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;
  opacity: 0.6;
  margin-top: -3rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 499px) {
    font-size: 2rem;
  }
`;

const ErrorText = styled.p`
  font-size: 1.5rem;
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;
  opacity: 0.6;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  font-size: 2rem;
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 499px) {
    font-size: 1.5rem;
  }
`;

const CodeTag = styled(Code)`
  display: inline-block;
  white-space: normal !important;
  font-size: 1rem;
  max-width: 100%;
  word-break: break-all;
  word-wrap: break-word;
`;

export const NotFound: React.FC = () => {
  const { state } = useLocation();
  const prevPath = (state as any)?.from?.pathname;

  return (
    <Container>
      <Heading>404</Heading>
      <SubHeading>Page not found</SubHeading>
      {prevPath && (
        <ErrorText>
          The following path <CodeTag>{prevPath}</CodeTag> is an invalid route.
        </ErrorText>
      )}
      <Link to="/">
        <Text>Go back home</Text>
      </Link>
    </Container>
  );
};
