import React, { useContext, useEffect, useMemo } from "react";
import { Subsea } from "@risk-harbor/subsea-sdk";
import invariant from "tiny-invariant";
import { Chain, useAccount } from "wagmi";
import { useConfig } from "./Config";
import { useAsyncMemo } from "../utils/hooks";
import { SUPPORTED_LOCAL_CHAINS, SUPPORTED_REMOTE_CHAINS } from "../contexts/Blockchain";
import { Logger } from "../utils/logger";
import { Maybe } from "../utils/maybe";

export type SDKContextValue = Map<Chain, Subsea>;

export const SDKContext = React.createContext<SDKContextValue | undefined>(
    undefined
);

export const SDKProvider: React.FC<any> = ({ children }) => {
    const config = useConfig();
    const sdks = useAsyncMemo(
        () =>
            Promise.all(
                SUPPORTED_LOCAL_CHAINS.map(async (chain): Promise<[Chain, Maybe<Subsea>]> => {
                    const querierAddr = config.queriers.get(chain.id);
                    try {
                        invariant(
                            querierAddr,
                            `Querier address doesn't exist for ${chain.name}`
                        );
                        const subsea = Subsea.custom(chain, { querierAddr, remoteChains: SUPPORTED_REMOTE_CHAINS });
                        await subsea.querier.load();

                        return [chain, Maybe.some(subsea)];
                    } catch (err) {
                        Logger.error(
                            `Error while initializing SDK (network: ${chain.name}, querier: ${querierAddr})`,
                            err
                        );
                        return [chain, Maybe.none()];
                    }
                })
            ),
        [config]
    );

    const account = useAccount();

    useEffect(() => {
        const maybeIntervals =
            sdks?.map(([, sdk]) => {
                return sdk.map((_sdk) => {
                    return _sdk.querier.poll(10_000, account.address, (err) => {
                        Logger.error("Error during querier poll:", err);
                    });
                });
            }) ?? [];

        return () => {
            maybeIntervals.forEach(maybeInterval => {
                maybeInterval.map((interval) => clearInterval(interval));
            });
        };
    }, [sdks, account.address]);

    const sdkRecord = useMemo(
        () =>
            new Map(
                (sdks ?? [])
                    .filter(([, maybeRh]) => maybeRh.isSome())
                    .map(([c, definitelyRh]) => [
                        c,
                        definitelyRh.required(),
                    ]) as [Chain, Subsea][]
            ),
        [sdks]
    );

    return (
        <SDKContext.Provider value={sdkRecord}>{children}</SDKContext.Provider>
    );
};

export function useSDK() {
    return useContext(SDKContext);
}
