import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Grid, GridItem } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const PageSelector = styled.div`
  float: right;
  color: var(--text-primary-white);
  font-weight: 600;
  margin-top: 1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media only screen and (max-width: 960px) {
    float: left;
  }
`;

const PageMover = styled.span<{ disabled: boolean }>`
  padding: 0.625rem;
  color: ${(props) =>
    props.disabled ? `#ffffff50 !important` : `none !important`};
  &:hover {
    cursor: pointer;
  }
`;

const SGrid = styled(Grid)`
  @media only screen and (max-width: 960px) {
    display: flex !important;
    flex-direction: column !important;
  }
`;

type Props<T extends { items: unknown[] }> = {
  name?: string;
} & T;

const PaginationActions = <T extends { items: unknown[] }>(
  { items, name }: Props<T>,
  ChildComponent: React.FC<Props<T>>
) => {
  const MAX_ROWS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(items.length / MAX_ROWS_PER_PAGE) || 1;

  const data = useMemo(
    () =>
      // eslint-disable-next-line
      items.slice(
        (currentPage - 1) * MAX_ROWS_PER_PAGE,
        Math.min(currentPage * MAX_ROWS_PER_PAGE, items.length) // eslint-disable-line
      ),
    [currentPage, items]
  );

  const setPage = (selectedPage: number) => {
    if (selectedPage < 1 || selectedPage > pageCount) return;
    setCurrentPage(selectedPage);
  };

  let isPortfolio = false;
  // eslint-disable-next-line
  if (name === 'portfolio' || name ===' bridge') {
    isPortfolio = true;
  }
  // const handleSearchInput = (query: string): void => {
  //   const filteredPool = props.pools.filter((pool) => {
  //     return pool.name.includes(query);
  //   });
  //   SetFilteredPools([...filteredPool].splice(0, maxPoolsList));
  // };

  return (
    <>
      <SGrid>
        {!isPortfolio && (
          <GridItem colSpan={6}>
            {/* <SearchBar onInputChange={handleSearchInput} /> */}
          </GridItem>
        )}
        <GridItem colStart={10} colEnd={12}>
          <PageSelector>
            <PageMover
              disabled={currentPage === 1}
              onClick={() => setPage(currentPage - 1)}
            >
              <ArrowBackIcon boxSize={5} />
            </PageMover>
            Page&nbsp;
            {currentPage} of&nbsp;{pageCount}
            <PageMover
              disabled={currentPage === pageCount}
              onClick={() => setPage(currentPage + 1)}
            >
              <ArrowForwardIcon boxSize={5} />
            </PageMover>
          </PageSelector>
        </GridItem>
      </SGrid>
      {/* @ts-ignore */}
      <ChildComponent items={data} />
    </>
  );
};

PaginationActions.defaultProps = {
  name: '',
};

export const PaginationHOC = <T extends { items: unknown[] }>(
  ChildComponent: React.FC<Props<T>>
): React.FC<Props<T>> => {
  return (props: Props<T>) => PaginationActions(props, ChildComponent);
};
