/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from 'react';
import { useConfig } from './Config';
import { useLoadingContext } from './Loading';
import { CMSPool, CMSService, CMSTokenOverride, CMSVault } from '../services';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { Chain } from 'wagmi';

export type CMSPoolWithVaultAndChain = CMSPool & {
  vault: CMSVault;
  chain: Chain;
};

export type CMSDataContextValue = {
  vaults: CMSVault[];
  pools: CMSPoolWithVaultAndChain[];
  tokenOverrides: CMSTokenOverride[];
};

export const CMSDataContext = React.createContext<Maybe<CMSDataContextValue>>(
  Maybe.none()
);

export function useCMSData() {
  return useContext(CMSDataContext);
}

export const CMSDataProvider: React.FC<any> = ({ children }) => {
  const [value, setValue] = useState<Maybe<CMSDataContextValue>>(Maybe.none());
  const { setCmsDataLoaded, cmsDataLoaded } = useLoadingContext();
  const config = useConfig();

  // We don't need to periodically refresh CMS data
  useEffect(() => {
    config.cmsBaseUrl.map(async (cmsBaseUrl) => {
      const [vaultsRes, tokenOverridesRes] = await Promise.all([
        CMSService.fromUrl(cmsBaseUrl).getVaults(),
        CMSService.fromUrl(cmsBaseUrl).getTokenOverrides(),
      ]);

      const { vaults, pools } = vaultsRes
        .map((_vaults) => {
          const _pools = _vaults.flatMap((v): CMSPoolWithVaultAndChain[] =>
            v.pools.map((p) => ({
              ...p,
              vault: v,
              chain: v.chain,
            }))
          );

          return { vaults: _vaults, pools: _pools };
        })
        .getOrElse({ vaults: [], pools: [] });

      const tokenOverrides = tokenOverridesRes.getOrElse([]);

      setValue(
        vaults.length > 0 || tokenOverrides.length > 0 || pools.length > 0
          ? Maybe.some({ vaults, pools, tokenOverrides })
          : Maybe.none()
      );
      setCmsDataLoaded();
    });
  }, [config.cmsBaseUrl, setCmsDataLoaded]);

  useEffect(() => {
    if (cmsDataLoaded) {
      value.map((d) => Logger.debug('CMS data loaded:', d));
    }
  }, [cmsDataLoaded, value]);

  return (
    <CMSDataContext.Provider value={value}>{children}</CMSDataContext.Provider>
  );
};
