import { Quote, SubseaTxQuoter } from "@risk-harbor/subsea-sdk";
import { ERC20 } from "@risk-harbor/subsea-contracts";
import { useRef, useState } from "react";
import { useConfig, useHeartbeat, useSDK, VaultWithChain } from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { Tx, TxHash } from "../actions/shared";
import { ToastTitles } from "../constants/constants";
import { getContract } from "@wagmi/core";
import { Hash } from "viem";
import { useUserBalances } from "../contexts/UserBalances";

type DepositQuoter = (typeof SubseaTxQuoter)["prototype"]["deposit"];

interface Deposit extends Tx {
    setDepositAmount(val: FixedPointNumber): void;
    enableRollover(): void;
    disableRollover(): void;
    quote(): Maybe<ReturnType<DepositQuoter>>;
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in useDeposit";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useDeposit";
const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in useDeposit";
const VAULT_NOT_LOADED_MESSAGE = "Vault not loaded in useDeposit";
const SDK_NOT_LOADED_MESSAGE = "SDK not loaded in useDeposit";

export function useDeposit(vault: Maybe<VaultWithChain>): Maybe<Deposit> {
    const [depositAmount, setDepositAmount] = useState<FixedPointNumber>(
        FixedPointNumber.zero
    );
    const [rolloverEnabled, setRolloverEnabled] = useState(false);
    const sdks = useSDK();
    const sdk = vault.flatMap((v) => Maybe.from(sdks?.get(v.chain)));
    const account = useAccount();
    const publicClient = usePublicClient();
    const heartbeat = useHeartbeat();
    const walletClient = useWalletClient();
    const notifications = useNotifications();
    const { defaultSlippage } = useConfig();
    const { refreshBalances } = useUserBalances();

    const enableRollover = useRef(() => {
        setRolloverEnabled(true);
    });

    const disableRollover = useRef(() => {
        setRolloverEnabled(false);
    });

    // TODO(jason): Handle insufficient balance here
    // (but in actual flow button should be disabled and this code path should never run)

    const depositAction = new Action<TxHash>();

    // ERC20#approve
    depositAction
        .addStep({
            async call(): Promise<TxHash> {
                const s = Maybe.from(walletClient.data).required(
                    SIGNER_NOT_LOADED_MESSAGE
                );
                const v = vault.required(VAULT_NOT_LOADED_MESSAGE);
                const undrErc20Addr = v.base.config.underwritingToken.address;
                const undrErc20 = getContract({
                    abi: ERC20.abi,
                    address: undrErc20Addr,
                    walletClient: s,
                });
                const txHash = await undrErc20.write.approve([
                    v.addr,
                    depositAmount.toBigInt()
                ]);

                return txHash;
            },
            async waitForSatisfaction(txHash: Hash): Promise<void> {
                const p = Maybe.from(publicClient).required(
                    PROVIDER_NOT_LOADED_MESSAGE
                );
                const v = vault.required(VAULT_NOT_LOADED_MESSAGE);

                const txHashLink = makeTxExplorerLink(v.chain, txHash);
                await waitForTxToMine(p, txHash);

                notifications.success(
                    "ERC20 Approval Successful",
                    txHashLink
                        .map((l) => (
                            <TxExplorerLink
                                explorerName={l.name}
                                explorerUrl={l.url}
                            />
                        ))
                        .getOrElse(() => <></>)
                );
            },
            async satisfied(): Promise<boolean> {
                const owner = Maybe.from(account.address).required(
                    WALLET_NOT_LOADED_MESSAGE
                );
                const v = vault.required(VAULT_NOT_LOADED_MESSAGE);
                const undrErc20Addr = v.base.config.underwritingToken.address;
                const undrErc20 = getContract({
                    abi: ERC20.abi,
                    address: undrErc20Addr,
                });
                const _allowance = await undrErc20.read.allowance([owner, v.addr]);
                return _allowance >= depositAmount.toBigInt();
            },
        })
        // RHVault#deposit
        .addStep({
            async call() {
                const s = Maybe.from(walletClient.data).required(
                    SIGNER_NOT_LOADED_MESSAGE
                );
                const v = vault.required(VAULT_NOT_LOADED_MESSAGE);
                const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);
                const tx = _sdk.txFactory.deposit({
                    vault: v.addr,
                    amount: depositAmount.toBigInt(),
                    rolloverPercentage: rolloverEnabled ? 100 : 0,
                    slippage: defaultSlippage,
                });

                const txHash = await tx.execute(s);
                heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after deposit
                refreshBalances('karak'); // refresh user's karak balances after deposit
                return txHash;
            },
            async waitForSatisfaction(txHash): Promise<void> {
                const p = Maybe.from(publicClient).required(
                    PROVIDER_NOT_LOADED_MESSAGE
                );
                const v = vault.required(VAULT_NOT_LOADED_MESSAGE);
                const txHashLink = makeTxExplorerLink(v.chain, txHash);
                await waitForTxToMine(p, txHash);
                notifications.success(
                    ToastTitles.Deposit.successMsg,
                    txHashLink
                        .map((l) => (
                            <TxExplorerLink
                                explorerName={l.name}
                                explorerUrl={l.url}
                            />
                        ))
                        .getOrElse(() => <></>)
                );
            },
            async satisfied(): Promise<boolean> {
                // terminal step
                return false;
            },
        });

    return Maybe.some<Deposit>({
        setDepositAmount(amount: FixedPointNumber) {
            if (amount.toBigInt() !== depositAmount.toBigInt()) {
                setDepositAmount(amount);
            }
        },
        enableRollover: enableRollover.current,
        disableRollover: disableRollover.current,
        quote(): Maybe<Quote.Deposit> {
            return sdk.zip(vault).map(([_sdk, _vault]) =>
                _sdk.txQuoter.deposit({
                    vault: _vault.addr,
                    amount: depositAmount.toBigInt(),
                    rolloverPercentage: rolloverEnabled
                        ? 100
                        : 0,
                    slippage: defaultSlippage,
                })
            );
        },
        async perform(): Promise<Result<TxHash, Error>> {
            let res: Result<TxHash, Error> = Result.error(
                new Error("Deposit had 0 actions")
            );

            while (!depositAction.completed) {
                res = await depositAction.step();
                if (res.isError) {
                    Logger.error(
                        "Transaction Failed",
                        res.mapErr((e) => e).getOrElse(() => new Error())
                    );
                    notifications.error(
                        ToastTitles.Deposit.errorMsg,
                        <Text>Check console for error</Text>
                    );
                    return res;
                }
            }

            return res;
        },
    });
}
