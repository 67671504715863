/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Maybe } from "./maybe";
import { Result } from "./result";

export interface Step<StepReturn> {
    call: () => Promise<StepReturn>;
    waitForSatisfaction: (stepReturn: StepReturn) => Promise<void>;
    satisfied: () => Promise<boolean>;
}

export class Action<StepReturn> {
    private readonly steps: Step<StepReturn>[] = [];

    constructor(readonly name: string = "Action") {}

    private lastSatisfiedStepIndex = -1;

    get nextStepId(): Maybe<number> {
        const id = this.lastSatisfiedStepIndex + 1;
        if (id < this.steps.length) {
            return Maybe.some(id);
        }

        return Maybe.none();
    }

    get completed(): boolean {
        return this.nextStepId.isNone();
    }

    addStep(s: Step<StepReturn>): this {
        this.steps.push(s);

        return this;
    }

    async seek() {
        if (this.lastSatisfiedStepIndex >= 0) {
            this.lastSatisfiedStepIndex -= 1;
        }

        for (const step of this.steps.slice(this.lastSatisfiedStepIndex + 1)) {
            if (await step.satisfied()) {
                this.lastSatisfiedStepIndex += 1;
            } else {
                break;
            }
        }
    }

    async step(): Promise<Result<StepReturn, Error>> {
        await this.seek();

        if (this.lastSatisfiedStepIndex + 1 === this.steps.length) {
            return Result.error(
                new Error(
                    `action already completed, currently at step ${
                        this.lastSatisfiedStepIndex + 1
                    } / ${this.steps.length}`
                )
            );
        }

        try {
            const nextStep = this.steps[this.lastSatisfiedStepIndex + 1];
            const res = await nextStep.call();
            await nextStep.waitForSatisfaction(res);
            this.lastSatisfiedStepIndex += 1;
            return Result.ok(res);
        } catch (err) {
            return Result.error(err as Error);
        }
    }
}
