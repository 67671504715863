import React from 'react';
import './App.css';

export const AppLoad: React.FC = () => {
  return (
    <div className="loadingContainer">
        <svg width="100%" height="100%" viewBox="0 0 1214 1214" fill="none" xmlns="http://www.w3.org/2000/svg"  className="loadingSvg">
        <g clipPath="url(#clip0_8605_8610)">
        <rect x="7" y="7" width="1200" height="1200" rx="600" fill="#15113E"/>
        <g filter="url(#filter0_f_8605_8610)">
        <ellipse cx="574.091" cy="721.294" rx="574.091" ry="721.294" transform="matrix(0.992407 0.122998 -0.0868427 0.996222 701.028 307.945)" fill="#A45CFF"/>
        </g>
        <g filter="url(#filter1_f_8605_8610)">
        <ellipse cx="555.978" cy="474.486" rx="555.978" ry="474.486" transform="matrix(0.943107 0.33249 -0.240787 0.970578 -211.5 64.6907)" fill="#304CCB"/>
        </g>
        <g filter="url(#filter2_f_8605_8610)">
        <path d="M555.358 880.449C108.633 793.276 14.2861 251.305 -143.187 246.116C-341.14 239.593 -345.817 735.959 -201.752 943.856C-31.9375 1188.91 858.097 939.525 555.358 880.449Z" fill="#00C2FF"/>
        </g>
        <g filter="url(#filter3_f_8605_8610)">
        <path d="M130.953 856.712C-18.5224 838.954 -97.0493 921.453 -108.19 1043.96C-131.438 1299.58 1179.75 1547.22 1398.95 1357.78C1560.04 1218.56 1561.27 995.721 1413.61 899C1246.74 789.696 1256.82 771.44 1078.6 821C929.893 862.351 759.364 935.887 597.313 966.406C445.635 994.971 263.725 872.485 130.953 856.712Z" fill="#4D0D8D"/>
        </g>
        <path d="M269.095 714.272C217.741 662.918 217.741 579.657 269.095 528.303L759.474 37.9241L945.443 223.893L455.064 714.272C403.71 765.626 320.449 765.626 269.095 714.272Z" fill="url(#paint0_linear_8605_8610)" stroke="url(#paint1_linear_8605_8610)" strokeWidth="2"/>
        <path d="M906.26 447.37C854.711 395.821 771.133 395.821 719.584 447.37L228.852 938.102L415.528 1124.78L906.26 634.046C957.81 582.497 957.81 498.919 906.26 447.37Z" fill="url(#paint2_linear_8605_8610)" stroke="url(#paint3_linear_8605_8610)"/>
        </g>
        <rect x="3.5" y="3.5" width="1207" height="1207" rx="603.5" stroke="url(#paint4_linear_8605_8610)" strokeWidth="7"/>
        <defs>
        <filter id="filter0_f_8605_8610" x="134.906" y="-124.956" width="2146.43" height="2444.17" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_8605_8610"/>
        </filter>
        <filter id="filter1_f_8605_8610" x="-758.165" y="-206.3" width="1913.52" height="1832.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="210" result="effect1_foregroundBlur_8605_8610"/>
        </filter>
        <filter id="filter2_f_8605_8610" x="-801.827" y="-253.948" width="1919.48" height="1803.61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_8605_8610"/>
        </filter>
        <filter id="filter3_f_8605_8610" x="-608.496" y="294.875" width="2630.63" height="1629.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="250" result="effect1_foregroundBlur_8605_8610"/>
        </filter>
        <linearGradient id="paint0_linear_8605_8610" x1="268.873" y1="715.464" x2="853.651" y2="130.687" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8605_8610" x1="443.872" y1="904.365" x2="502.372" y2="132.365" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0.47"/>
        <stop offset="1" stopColor="#929292" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_8605_8610" x1="907.099" y1="447.502" x2="322.322" y2="1032.28" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_8605_8610" x1="1096" y1="622.5" x2="324" y2="681" gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0.47"/>
        <stop offset="1" stopColor="#929292" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_8605_8610" x1="607" y1="7" x2="607" y2="1207" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B3BAD1"/>
        <stop offset="1" stopColor="#9296A4"/>
        </linearGradient>
        <clipPath id="clip0_8605_8610">
        <rect x="7" y="7" width="1200" height="1200" rx="600" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      <div className="pulse-ring" />
    </div>
  );
};
