import moment from "moment";
import { Fraction, PoolDefaultStatus } from "@risk-harbor/subsea-sdk";
import { PoolWithVaultAndChain, VaultWithChain } from "../contexts";
import { CMSPoolWithVaultAndChain } from "../contexts/CMSData";
import { CMSVault } from "../services";
import { PoolListItemView, VaultListItemView } from "../types/types";
import { getAddressFromString } from "./address";
import { Time } from "./time";
import { Maybe } from "./maybe";
import { nameFallback } from "./cmsfallbacks";
import { getTokenIcon } from "./token-icon";
import { calculateYield, mapRawAmountToUi, sigFigFormat } from "./helpers";

export class Transformer {
    static vaultsToListViews = (
        onchain: VaultWithChain[],
        cmsData: CMSVault[]
    ): VaultListItemView[] => {
        const cmsDataRecord = cmsData.reduce((map, cmsVault) => {
            const cmsVaultAddrNormalized = getAddressFromString(
                cmsVault.address
            );
            const networkNormalized = cmsVault.chain.name.toLowerCase();
            const key = `${networkNormalized}.${cmsVaultAddrNormalized}`;

            map.set(key, cmsVault);

            return map;
        }, new Map<string, CMSVault>());

        return onchain.map((vault) => {
            const addrNormalized = getAddressFromString(vault.addr);
            const networkNormalized = vault.chain.name.toLowerCase();
            const key = `${networkNormalized}.${addrNormalized}`;
            const cmsVault = Maybe.from(cmsDataRecord.get(key));
            const undrToken = vault.base.config.underwritingToken;
            const expiry = new Date(vault.base.config.expiration * 1e3);

            const view: VaultListItemView = {
                vaultID: addrNormalized,
                name: cmsVault.map((v) => v.name).getOrElse(nameFallback),
                underwritingToken: `${getTokenIcon(
                    vault.chain.id,
                    undrToken.address
                )}&${undrToken.symbol}`,
                network: vault.chain.name,
                apy: `${calculateYield(vault.base).toFixed(2)}%`,
                capacity: `${sigFigFormat(
                    mapRawAmountToUi(
                        vault.base.state.allocationVector[0].toString(),
                        undrToken.decimals
                    )
                )} ${undrToken.symbol}`,
                expiryDate: `${moment(expiry.toUTCString()).format(
                    "MMM. D, YYYY"
                )}`,
                paused: vault.base.state.isPaused,
                expired: expiry.getTime() < Date.now(),
            };

            return view;
        });
    };

    static poolsToListViews = (
        onchain: PoolWithVaultAndChain[],
        cmsData: CMSPoolWithVaultAndChain[]
    ): PoolListItemView[] => {
        const cmsDataRecord = cmsData.reduce((map, cmsPool) => {
            const networkNormalized = cmsPool.chain.name.toLowerCase();
            const cmsVaultAddrNormalized = getAddressFromString(
                cmsPool.vault.address
            );
            const { poolId } = cmsPool;
            const key = `${networkNormalized}.${cmsVaultAddrNormalized}.${poolId}`;

            map.set(key, cmsPool);

            return map;
        }, new Map<string, CMSPoolWithVaultAndChain>());

        const data = onchain
            .filter((p) =>
                Time.fromEvmTimestamp(p.vault.base.config.expiration).isAfter(
                    Time.now
                )
            )
            .filter((p) => p.vault.base.config.poolCapacityCap[p.id - 1] !== BigInt(0))
            .map((pool) => {
                const networkNormalized = pool.vault.chain.name.toLowerCase();
                const addrNormalized = getAddressFromString(pool.vault.addr);
                const poolId = pool.id;
                const key = `${networkNormalized}.${addrNormalized}.${poolId}`;

                const cmsPool = Maybe.from(cmsDataRecord.get(key));
                const undrToken = pool.vault.base.config.underwritingToken;
                const expiry = new Date(
                    pool.vault.base.config.expiration * 1e3
                );

                const view: PoolListItemView = {
                    name: cmsPool.map((p) => p.name).getOrElse(nameFallback),
                    protectedToken: `${getTokenIcon(
                        pool.config.remoteProtectedToken.chainId,
                        pool.config.remoteProtectedToken.address
                    )}&${pool.config.remoteProtectedToken.symbol}`,
                    underwritingToken: `${getTokenIcon(
                        pool.vault.chain.id,
                        undrToken.address
                    )}&${undrToken.symbol}`,
                    poolID: poolId.toString(),
                    vaultID: addrNormalized,
                    hacked:
                        pool.state.defaultStatus === PoolDefaultStatus.Default,
                    paused: pool.vault.base.state.isPaused,
                    network: pool.vault.chain.name,
                    expiryDate: `${moment(expiry.toUTCString()).format(
                        "MMM. D, YYYY"
                    )}`,
                    // TODO(zak): The consumers of this field might be assuming its in the same chain (so, cross check and update if needed)
                    protectedTokenAddress: getAddressFromString(
                        pool.config.remoteProtectedToken.address
                    ),
                    underwritingTokenAddress: getAddressFromString(
                        undrToken.address
                    ),
                };

                return view;
            });
        return data;
    };

    static undrToProt =
        (payoutRatio: Fraction) =>
        (prot: bigint): bigint => {
            return (prot * payoutRatio.denominator) / payoutRatio.numerator;
        };
}
