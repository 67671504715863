import { getAddressFromString } from "./address";
import { serializeNetworkNameToRouteParam } from "./network";

export class SubseaRoutes {
    static vaultTemplate(): string {
        return `/vault/:network/:vaultID`;
    }

    static vaultBaseRegex(): RegExp {
        return /^\/vault\//;
    }

    static vaultExactRegex(): RegExp {
        return /^\/vault\/(\w+)\/(0x[0-9a-fA-F]{40})$/;
    }

    static vault(network: string, vaultAddr: string): string {
        return `/vault/${serializeNetworkNameToRouteParam(
            network
        )}/${getAddressFromString(vaultAddr)}`;
    }

    static poolTemplate(): string {
        return `/pool/:network/:vaultID/:poolID`;
    }

    static poolBaseRegex(): RegExp {
        return /^\/pool\//;
    }

    static poolExactRegex(): RegExp {
        // Assuming poolId can't be more than 2 digits
        return /^\/pool\/(\w+)\/(0x[0-9a-fA-F]{40})\/([0-9]{1,2})$/;
    }

    static pool(
        network: string,
        vaultAddr: string,
        poolId: number | string
    ): string {
        return `/pool/${serializeNetworkNameToRouteParam(
            network
        )}/${getAddressFromString(vaultAddr)}/${poolId}`;
    }
}
