import React from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import shield from '../../assets/transparent-logos/shield.svg';
import coin from '../../assets/transparent-logos/coin.svg';
import notes from '../../assets/transparent-logos/notes.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 255px;
  align-items: left;

  @media only screen and (max-width: 796px) {
    flex-direction: row;
    justify-content: space-between;
    margin: auto auto 1rem auto;
  }

  @media (max-width: 499px) {
    display: none !important;
  }
`;

const Image = styled.img`
  width: 1.5rem;
  margin-right: 0.3rem;

  @media only screen and (max-width: 796px) {
    width: 1rem;
  }
`;

const Text = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  margin: 0 !important;

  @media only screen and (max-width: 796px) {
    font-size: var(--p2-font-size);
  }
`;

export const DesktopMenu: React.FC = () => {
  const tabText1 = 'Buy Protection';
  const tabText2 = 'Provide Protection';
  const tabText3 = 'Portfolio';

  const { pathname: menuPath } = useLocation();

  return (
    <Container>
      <RouteLink to="/buy-protection">
        <Button
          bgColor={
            menuPath === '/buy-protection' || menuPath.includes('pool')
              ? 'var(--background-purple)'
              : 'transparent'
          }
          border={
            menuPath === '/buy-protection' || menuPath.includes('pool')
              ? '1px solid var(--border-purple)'
              : 'none'
          }
          display="flex"
          justifyContent="flex-start"
          alignItems="center !important"
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor="var(--text-primary-white)"
          background="transparent"
          mb={4}
          maxWidth="13.25rem"
          tabIndex={0}
          className="menu-button"
        >
          <Image src={shield} alt="shield" />
          <Text>{tabText1}</Text>
        </Button>
      </RouteLink>
      <RouteLink to="/provide-protection">
        <Button
          bgColor={
            menuPath === '/provide-protection' || menuPath.includes('vault')
              ? 'var(--background-purple)'
              : 'transparent'
          }
          border={
            menuPath === '/provide-protection' || menuPath.includes('vault')
              ? '1px solid var(--border-purple)'
              : 'none'
          }
          display="flex"
          justifyContent="flex-start"
          alignItems="center !important"
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor="var(--text-primary-white)"
          background="transparent"
          mb={4}
          maxWidth="13.25rem"
          tabIndex={0}
          className="menu-button"
        >
          <Image src={coin} alt="coin" />
          <Text>{tabText2}</Text>
        </Button>
      </RouteLink>
      <RouteLink to="/portfolio">
        <Button
          bgColor={
            menuPath === '/portfolio'
              ? 'var(--background-purple)'
              : 'transparent'
          }
          border={
            menuPath === '/portfolio'
              ? '1px solid var(--border-purple)'
              : 'none'
          }
          display="flex"
          justifyContent="flex-start"
          alignItems="center !important"
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor="var(--text-primary-white)"
          background="transparent"
          mb={4}
          maxWidth="13.25rem"
          tabIndex={0}
          className="menu-button"
        >
          <Image src={notes} alt="notes" />
          <Text>{tabText3}</Text>
        </Button>
      </RouteLink>
    </Container>
  );
};
