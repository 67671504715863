import { ethers } from 'ethers';
import {
  SignerOrProviderLike,
  CrossChainMessenger,
  ETHBridgeAdapter,
  StandardBridgeAdapter,
} from "@eth-optimism/sdk"; // eslint-disable-line

export const l1Contracts = {
  StateCommitmentChain: ethers.constants.AddressZero,
  CanonicalTransactionChain: ethers.constants.AddressZero,
  BondManager: ethers.constants.AddressZero,
  AddressManager: '0xF2C89960B6D63eC6c61dF3EA8BaFa0a02c26e8C9', // Lib_AddressManager.json
  L1CrossDomainMessenger: '0x9BFfA66a8FcAAd7AC9ea7c7d4b9a6fc46777022d', // Proxy__OVM_L1CrossDomainMessenger.json
  L1StandardBridge: '0xBA61F25dd9f2d5f02D01B1C2c1c5F0B14c4B48A3', // Proxy__OVM_L1StandardBridge.json
  OptimismPortal: '0xeeCE9CD7Abd1CC84d9dfc7493e7e68079E47eA73', // OptimismPortalProxy.json
  L2OutputOracle: '0x0a23342520Aa8Ca963c4201801F4D3E95e731637', // L2OutputOracleProxy.json
};

const bridges = {
  Standard: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: StandardBridgeAdapter,
  },
  ETH: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: ETHBridgeAdapter,
  },
};

export const erc20Addrs = {
  l1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC eth mainnet
  l2: '0xa415021bC5c4C3b5B989116DC35Ae95D9C962c8D', // USDC karak l2
};

export const createCrossChainMessenger = async (
  l1Signer: SignerOrProviderLike,
  l2Signer: SignerOrProviderLike
) => {
  const crossChainMessenger = new CrossChainMessenger({
    bedrock: true,
    l1ChainId: 1, // eth mainnet chain id
    l2ChainId: 2410, // karak chain id
    l1SignerOrProvider: l1Signer,
    l2SignerOrProvider: l2Signer,
    contracts: {
      l1: l1Contracts,
    },
    bridges,
  });

  return crossChainMessenger;
};
