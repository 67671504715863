import { getBasedOnEnv } from './env';

/* eslint-disable @typescript-eslint/no-explicit-any */
class _Logger {
  // Define the different log levels
  static readonly DEBUG = 1;

  static readonly INFO = 2;

  static readonly WARN = 3;

  static readonly ERROR = 4;

  // Define the different color values
  static readonly COLOR_BLACK = '\x1b[30m';

  static readonly COLOR_RED = '\x1b[31m';

  static readonly COLOR_GREEN = '\x1b[32m';

  static readonly COLOR_YELLOW = '\x1b[33m';

  static readonly COLOR_BLUE = '\x1b[34m';

  static readonly COLOR_MAGENTA = '\x1b[35m';

  static readonly COLOR_CYAN = '\x1b[36m';

  static readonly COLOR_WHITE = '\x1b[37m';

  static readonly COLOR_RESET = '\x1b[0m';

  // Set the initial log level to debug
  private level: number = _Logger.DEBUG;

  public static withLevel(level: number) {
    const logger = new _Logger();
    logger.setLevel(level);
    return logger;
  }

  // Method to set the log level
  public setLevel(level: number) {
    this.level = level;
  }

  // Method to log a message at a specified log level
  private log(level: number, ...items: any[]) {
    if (this.level <= level) {
      let prefix = '[DEBUG]';
      let color = _Logger.COLOR_CYAN;
      if (level === _Logger.INFO) {
        prefix = '[INFO]';
        color = _Logger.COLOR_GREEN;
      } else if (level === _Logger.WARN) {
        prefix = '[WARN]';
        color = _Logger.COLOR_YELLOW;
      } else if (level === _Logger.ERROR) {
        prefix = '[ERROR]';
        color = _Logger.COLOR_RED;
      }
      // eslint-disable-next-line no-console
      console.log(`${color}${prefix}${_Logger.COLOR_RESET}`, ...items);
    }
  }

  // Method to log a debug message
  public debug = (...items: any[]) => {
    this.log(_Logger.DEBUG, ...items);
  };

  // Method to log an info message
  public info = (...items: any[]) => {
    this.log(_Logger.INFO, ...items);
  };

  // Method to log a warn message
  public warn = (...items: any[]) => {
    this.log(_Logger.WARN, ...items);
  };

  // Method to log an error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error = (error: any, ...items: any[]) => {
    if (error instanceof Error) {
      this.log(_Logger.ERROR, error.message, ...items);
    } else {
      this.log(_Logger.ERROR, error, ...items);
    }
  };
}

export const Logger = _Logger.withLevel(
  getBasedOnEnv({
    production: _Logger.INFO,
    development: _Logger.DEBUG,
    staging: _Logger.DEBUG,
  })
);
