import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Banner } from '../components/Banner/Banner';
import { OrderBox } from '../components/OrderBox';
import { InfoDesc } from '../components/PoolVaultInfo/InfoDesc';
import { PoolBreakDown } from '../components/Tables/PoolBreakDown';
import {
  useVaultPageContext,
  VaultPageContextProvider,
} from '../contexts/VaultPage';
import { getAddressFromString } from '../utils/address';
import { Maybe } from '../utils/maybe';
import { PoolDefaultStatus } from '@risk-harbor/subsea-sdk';
import { VaultPoolView } from '../types/types';
import {
  underwriterRisksFallback,
  vaultDescriptionFallback,
} from '../utils/cmsfallbacks';
import { getTokenIcon } from '../utils/token-icon';
import { calculatePoolRiskScores } from '../utils/poolRiskScores';
import { SECONDS_PER_YEAR } from '../constants/constants';
import Decimal from 'decimal.js';

const Section = styled.div`
  display: flex;
  column-gap: 2rem !important;

  @media only screen and (max-width: 1109px) {
    flex-direction: column;
    column-gap: 0rem !important;
  }
`;

const SubSection = styled.div`
  width: 54%;

  @media only screen and (max-width: 1109px) {
    width: 100%;
  }
`;

const formatRiskScore = (val: Decimal): string => {
  return `${val.mul(new Decimal(100)).toDecimalPlaces(2)}%`;
};

const VaultInner: React.FC = () => {
  const vaultPageData = useVaultPageContext();

  const poolsInVault: Maybe<VaultPoolView[]> = useMemo(
    () =>
      vaultPageData.map(({ vault }) => {
        const ttl =
          (vault.onchain.base.config.expiration -
            vault.onchain.base.config.start) /
          SECONDS_PER_YEAR;
        const poolRiskScores = calculatePoolRiskScores(
          vault.onchain.amm.config,
          ttl
        );
        return vault.onchain.pools.filter((pool) => vault.onchain.base.config.poolCapacityCap[pool.id - 1] !== BigInt(0)).map(
          (pool): VaultPoolView => ({
            vaultID: getAddressFromString(vault.onchain.addr),
            poolID: pool.id,
            protectedToken: `${getTokenIcon(
              pool.config.remoteProtectedToken.chainId,
              pool.config.remoteProtectedToken.address,
            )}&${pool.config.remoteProtectedToken.symbol}`,
            poolRiskScore: formatRiskScore(poolRiskScores[pool.id]),
            hacked: pool.state.defaultStatus === PoolDefaultStatus.Default,
          })
        );
      }),
    [vaultPageData]
  );

  return (
    <>
      <Banner type="underwrite" />
      <Section>
        <SubSection>
          <InfoDesc
            type="vault"
            description={vaultPageData
              .flatMap((d) => d.vault.cms)
              .map((v) => v.description)
              .getOrElse(vaultDescriptionFallback)}
            risksDesc={vaultPageData
              .flatMap((d) => d.vault.cms)
              .map((v) => v.underwriterRisks)
              .getOrElse(underwriterRisksFallback)}
          />
        </SubSection>
        <OrderBox
          type="vault"
        />
      </Section>
      <PoolBreakDown
        pools={
          vaultPageData
            .map(({ vault }) => vault.onchain.chain.name)
            .string() === 'Avalanche'
            ? poolsInVault.getOrElse(Array).filter((p) => p.poolID !== 6)
            : poolsInVault.getOrElse(Array)
        }
        chain={vaultPageData.map(({ vault }) => vault.onchain.chain)}
      />
    </>
  );
};

export const Vault: React.FC = () => {
  return (
    <VaultPageContextProvider>
      <VaultInner />
    </VaultPageContextProvider>
  );
};
