import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Button, Spinner } from '@chakra-ui/react';
import { ButtonConnect } from '../Header/ButtonConnect';
import { OrderBoxActions } from '../../constants/constants';
import { useAccount } from 'wagmi';

const StatusButton: typeof Button = styled(Button)<{ isDisabled: boolean }>`
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: var(--h2-font-size) !important;
  text-align: center !important;
  font-weight: 600 !important;
  width: 100% !important;
  padding: 2rem 0rem !important;
  border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  color: ${(props) =>
    props.isDisabled
      ? `var(--text-secondary-gray) !important`
      : `var(--text-primary-white) !important`};
  background: ${(props) =>
    props.isDisabled
      ? `var(--background-purple) !important`
      : `var(--components-primary-purple) !important`};

  &:hover {
    background: ${(props) =>
      props.isDisabled
        ? `var(--background-purple) !important`
        : `var(--components-primary-purple) !important`};
    filter: ${(props) =>
      props.isDisabled ? `brightness(100%)` : `brightness(80%)`};
  }

  &:focus {
    box-shadow: none !important;
  }
`;

interface Props {
  actionType: string;
  disabled?: boolean;
  zeroAmount?: boolean;
  insufficientFunds?: boolean;
  poolCapacityExceeded?: boolean;
  vaultPaused?: boolean;
  lowPremium?: boolean;
  blockedUser?: boolean;
  // loading?: boolean;
  // loadingText?: string;
  onClick?: () => Promise<unknown>;
  onConnect?: () => void;
  children?: ReactNode;
}

export const OrderButton: React.FC<Props> = ({
  actionType,
  disabled,
  zeroAmount,
  insufficientFunds,
  poolCapacityExceeded,
  vaultPaused,
  lowPremium,
  blockedUser,
  onClick,
  onConnect,
}) => {
  const [loading, setLoading] = useState(false);
  const isDisabled = false; // disabled if wallet not connected or not enough balance
  let submitText = 'Enter Amount';
  const { isConnected } = useAccount();

  if (!isDisabled) {
    submitText = OrderBoxActions[actionType];
  }

  if (!isConnected) {
    return <ButtonConnect location="orderbox" onClick={onConnect} />;
  }

  if (zeroAmount) {
    submitText = 'ENTER AMOUNT';
  }

  if (insufficientFunds) {
    submitText = 'INSUFFICIENT FUNDS';
  }

  if (poolCapacityExceeded) {
    submitText = 'POOL CAPACITY EXCEEDED';
  }

  if (lowPremium) {
    submitText = 'PREMIUM TOO LOW';
  }

  if (blockedUser) {
    submitText = 'NOT AVAILABLE IN YOUR REGION';
  }

  if (vaultPaused) {
    return <StatusButton isDisabled>Vault is currently paused</StatusButton>;
  }

  return (
    <StatusButton
      isDisabled={
        blockedUser ||
        isDisabled ||
        zeroAmount ||
        disabled ||
        insufficientFunds ||
        poolCapacityExceeded ||
        lowPremium ||
        loading
      }
      onClick={() => {
        setLoading(true);
        onClick?.()
          .catch(() => {})
          .finally(() => setLoading(false));
      }}
    >
      {loading ? <Spinner /> : submitText}
    </StatusButton>
  );
};

OrderButton.defaultProps = {
  zeroAmount: false,
  disabled: false,
  insufficientFunds: false,
  poolCapacityExceeded: false,
  vaultPaused: false,
  lowPremium: false,
  blockedUser: false,
  onClick: async () => '',
  onConnect: () => {},
  // loadingText: '',
  // loading: false,
};
