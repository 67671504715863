import React from 'react';
import styled from 'styled-components';
import { VStack } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';

const NotFoundText = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

interface Props {
  message: string;
}

export const SearchNotFound: React.FC<Props> = ({ message }) => {
  return (
    <VStack>
      <QuestionIcon boxSize={12} color="whiteAlpha.700" mb={3} />
      <NotFoundText>{message}</NotFoundText>
    </VStack>
  );
};
