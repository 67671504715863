import React from 'react';
import { useVaultPageContext } from '../../contexts/VaultPage';
import { nameFallback } from '../../utils/cmsfallbacks';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  width: 100%;

  @media only screen and (max-width: 1473px) {
    margin-bottom: 1rem;
    margin-left: 0rem;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-weight: 700;
  text-align: center;

  @media only screen and (max-width: 1473px) {
    text-align: left;
  }

  @media only screen and (max-width: 325px) {
    font-size: var(--h2-font-size);
  }
`;

export const UnderwriteInfo: React.FC = () => {
  const vaultPageContext = useVaultPageContext();
  const vaultName = vaultPageContext
    .flatMap((c) => c.vault.cms)
    .map((v) => v.name)
    .getOrElse(nameFallback);

  return (
    <Container>
      <LargeText>{vaultName}</LargeText>
    </Container>
  );
};
