import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useConfig } from './Config';
import { Logger } from '../utils/logger';
import { useIdleTimer } from 'react-idle-timer';

export type HeartbeatContextValue = {
  pulse: bigint;
  sendPulse(): void;
};
const HeartbeatContext = React.createContext<HeartbeatContextValue>({
  pulse: BigInt(0),
  sendPulse() {},
});

export function useHeartbeat() {
  return useContext(HeartbeatContext);
}

export const HeartbeatProvider: React.FC<any> = ({ children }) => {
  const config = useConfig();
  const [heartbeat, setHeartbeat] = useState(BigInt(0));
  const [userState, setUserState] = useState<string>('Active');

  useEffect(() => {
    let interval: NodeJS.Timer;

    const startHeartbeat = () => {
      interval = setInterval(() => {
        setHeartbeat((hb) => hb + BigInt(1));
      }, config.heartbeatFrequencyMs);
    };

    const stopHeartbeat = () => {
      clearInterval(interval);
    };

    if (userState === 'Active') startHeartbeat();
    if (userState === 'Idle') stopHeartbeat();

    return stopHeartbeat;
  }, [config.heartbeatFrequencyMs, userState]);

  useEffect(() => {
    Logger.info(`Heartbeat (${heartbeat.toString()}) -`, new Date());
  }, [heartbeat]);

  const sendPulse = useRef(() => {
    setHeartbeat((p) => p + BigInt(1));
  });

  const onIdle = () => {
    setUserState('Idle');
  }

  const onActive = () => {
    setUserState('Active');
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 120_000, // 120 seconds === 2 min
    throttle: 500
  });

  const value = useMemo(
    () => ({
      pulse: heartbeat,
      sendPulse: sendPulse.current,
    }),
    [heartbeat]
  );

  return (
    <HeartbeatContext.Provider value={value}>
      {children}
    </HeartbeatContext.Provider>
  );
};
