import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

interface Props {
  tooltipText: string;
  position?: boolean;
  width?: string;
}

export const ToolTip: React.FC<Props> = ({ tooltipText, position, width }) => {
  return (
    <>
      {position ? (
        <>
          <Tooltip
            label={tooltipText}
            fontSize="sm"
            w="75%"
            placement="bottom"
            borderRadius="5px"
          >
            <QuestionOutlineIcon mt="1px" />
          </Tooltip>
        </>
      ) : (
        <>
          &nbsp;&nbsp;
          <Tooltip
            label={tooltipText}
            fontSize="sm"
            w={width || '75%'}
            placement="bottom"
            borderRadius="5px"
          >
            <QuestionOutlineIcon mb={0.5} />
          </Tooltip>
        </>
      )}
    </>
  );
};

ToolTip.defaultProps = {
  position: false,
  width: '',
};
