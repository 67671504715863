import { Fraction, ONE_X18 } from "@risk-harbor/subsea-sdk";
import Decimal from "decimal.js";

export class RHFraction {
    private constructor(private readonly fraction: Fraction) {}

    static fromFraction(fraction: Fraction) {
        return new RHFraction(fraction);
    }

    static fromPercentage(percentage: Decimal | string | number): RHFraction {
        const percentageDec = new Decimal(percentage.toString());
        const numStr = percentageDec.mul(ONE_X18.toString()).round().toString();
        const numBigIntX18 = BigInt(numStr);
        const denomBigIntX20 = ONE_X18 * BigInt(100);

        return RHFraction.fromFraction({
            numerator: numBigIntX18,
            denominator: denomBigIntX20,
        });
    }

    toFraction(): Fraction {
        return this.fraction;
    }
}
