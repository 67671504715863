import { Address } from "@risk-harbor/subsea-sdk";
import { SUPPORTED_LOCAL_CHAINS } from "../contexts/Blockchain";
import { getAddressFromString } from "../utils/address";
import { Logger } from "../utils/logger";
import { Maybe } from "../utils/maybe";
import { Result } from "../utils/result";
import invariant from "tiny-invariant";
import { Chain } from "wagmi";

export enum RiskAversion {
    RiskOn = "Risk-On",
    RiskOff = "Risk-Off",
    Cautious = "Cautious",
}

export type CMSPool = {
    name: string;
    description: string;
    policyholderRisks: string;
    underlyingTokensDisplay: string[];
    defaultRatioDisplay: string[];
    poolId: number;
    riskScore: number;
    protocolName: string;
};

export type CMSVault = {
    address: Address;
    riskAversion: RiskAversion;
    name: string;
    description: string;
    underwriterRisks: string;
    isArchived: boolean;
    chain: Chain;
    pools: CMSPool[];
    ticketRewards: number;
};

export type CMSTokenOverride = {
    address: Address;
    symbolOverride: string;
    chain: Chain;
};

type CMSNetworkRaw = {
    attributes: {
        name: string;
    };
};

type CMSPoolRaw = {
    attributes: CMSPool;
};

// TODO(jason): Add pools and network entities nested
type CMSVaultRaw = {
    attributes: {
        riskAversion: string;
        name: string;
        description: string;
        underwriterRisks: string;
        isArchived: boolean;
        address: string;
        ticketRewards: number;
        network: {
            data: CMSNetworkRaw;
        };
        pools: {
            data: CMSPoolRaw[];
        };
    };
};

type CMSTokenOverrideRaw = {
    attributes: {
        address: string;
        symbolOverride: string;
        network: {
            data: CMSNetworkRaw;
        };
    };
};

export class CMSService {
    private endpoint: string;

    private static MAX_PAGE_SIZE = 100;

    private constructor(endpoint: string) {
        this.endpoint = endpoint;
    }

    static fromUrl = (baseUrl: string) => {
        return new CMSService(baseUrl);
    };

    public async getVaults(
        // TODO(drew): What should default be here?
        includeArchived = true
    ): Promise<Result<CMSVault[], Error>> {
        try {
            const response = await fetch(
                `${
                    this.endpoint
                }/vaults?populate[0]=network&populate[1]=pools&pagination[pageSize]=${
                    CMSService.MAX_PAGE_SIZE
                }&${includeArchived ? "" : "filters[isArchived][$eq]=false"}`
            );

            const rawVaults: CMSVaultRaw[] = (await response.json()).data;

            return Result.ok(CMSService.mapRawVaultsToDomain(rawVaults));
        } catch (err) {
            invariant(err instanceof Error, `${err} not an error in catch`);

            Logger.error(err, "Error occurred during CMS vaults fetching.");
            return Result.error(err);
        }
    }

    public async getTokenOverrides(): Promise<
        Result<CMSTokenOverride[], Error>
    > {
        try {
            const response = await fetch(
                `${this.endpoint}/token-overrides?populate[0]=network&pagination[pageSize]=${CMSService.MAX_PAGE_SIZE}`
            );

            const rawTokenOverrides: CMSTokenOverrideRaw[] = (
                await response.json()
            ).data;

            return Result.ok(
                CMSService.mapRawTokenOverridesToDomain(rawTokenOverrides)
            );
        } catch (err) {
            invariant(err instanceof Error, `${err} not an error in catch`);

            Logger.error(
                err,
                "Error occurred during CMS token-overrides fetching."
            );
            return Result.error(err);
        }
    }

    private static mapRawTokenOverridesToDomain(
        raw: CMSTokenOverrideRaw[]
    ): CMSTokenOverride[] {
        return raw
            .map((t) => ({
                address: getAddressFromString(t.attributes.address.trim()),
                symbolOverride: t.attributes.symbolOverride,
                chain: Maybe.from(
                    SUPPORTED_LOCAL_CHAINS.find(
                        (c) =>
                            c.name.toLowerCase() ===
                            t.attributes.network.data.attributes.name.toLowerCase()
                    )
                ),
            }))
            .filter((t) => t.chain.isSome())
            .map(
                (v): CMSTokenOverride => ({
                    ...v,
                    chain: v.chain.required(
                        "Error while extracting chain from token-override CMS data"
                    ),
                })
            );
    }

    private static mapRawVaultsToDomain(raw: CMSVaultRaw[]): CMSVault[] {
        return raw
            .map((v) => ({
                address: getAddressFromString(v.attributes.address.trim()),
                riskAversion: CMSService.parseRiskAversion(
                    v.attributes.riskAversion.trim()
                ),
                name: v.attributes.name.trim(),
                description: v.attributes.description,
                underwriterRisks: v.attributes.underwriterRisks,
                isArchived: v.attributes.isArchived,
                pools: v.attributes.pools.data.map((p) => p.attributes),
                ticketRewards: v.attributes.ticketRewards,
                chain: Maybe.from(
                    SUPPORTED_LOCAL_CHAINS.find(
                        (c) =>
                            c.name.toLowerCase() ===
                            v.attributes.network.data.attributes.name.toLowerCase()
                    )
                ),
            }))
            .filter((v) => v.chain.isSome())
            .map(
                (v): CMSVault => ({
                    ...v,
                    chain: v.chain.required(
                        "Error while extracting chain from vault CMS data"
                    ),
                })
            );
    }

    private static parseRiskAversion(raw: string): RiskAversion {
        switch (raw) {
            case "Risk-On":
                return RiskAversion.RiskOn;
            case "Cautious":
                return RiskAversion.Cautious;
            case "Risk-Off":
                return RiskAversion.RiskOff;
            default:
                throw new Error(`Unknown Risk Aversion: ${raw}`);
        }
    }
}
