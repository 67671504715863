import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { VaultPoolView } from '../../types/types';
import { useTable } from 'react-table';
import emptyTokenIcon from '../../assets/empty-token.png';
import { SubseaRoutes } from '../../utils/routes';
import { Chain } from 'wagmi';
import { Maybe } from '../../utils/maybe';

interface Props {
  pools: VaultPoolView[];
  chain: Maybe<Chain>;
}

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;
  width: 40rem !important;
  margin-top: -1rem;

  tr td:nth-child(n) {
    background-color: var(--table-background-purple);
  }

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }

  @media only screen and (max-width: 1109px) {
    margin-top: 0rem;
  }
`;

const STr = styled(Tr)`
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const Image = styled.img`
  width: 25px;
  margin-right: 10px;
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Token: React.FC<{ value: string }> = ({ value }) => {
  const [tokenIcon, tokenSymbol] = value.split('&');

  return (
    <HFlexbox>
      <Image
        src={tokenIcon}
        alt=""
        onError={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e.target as any).src = emptyTokenIcon;
        }}
      />
      <TokenSymbol>{tokenSymbol}</TokenSymbol>
    </HFlexbox>
  );
};

export const PoolBreakDown: React.FC<Props> = ({ pools, chain }) => {
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      pools
        ? pools.map((pool: VaultPoolView) => ({
            protectedToken: pool.protectedToken,
            riskScore: pool.poolRiskScore,
            hacked: pool.hacked ? 'Yes' : 'No',
            poolID: pool.poolID,
            vaultID: pool.vaultID,
            network: chain.map((c) => c.name).string(),
          }))
        : [],
    [chain, pools]
  );

  const columns = useMemo(
    () =>
      pools
        ? [
            {
              Header: 'PROTECTED TOKEN',
              accessor: 'protectedToken',
              Cell: Token,
            },
            {
              Header: 'POOL RISK SCORE',
              accessor: 'riskScore',
            },
            {
              Header: 'HACKED',
              accessor: 'hacked',
            },
          ]
        : [],
    [pools]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns,
      data,
    });

  return (
    <Box overflowX="auto">
      <STable variant="unstyled" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => {
                if (index === 1) {
                  return (
                    <React.Fragment key={index}>
                      <STh
                        {...column.getHeaderProps()}
                        textAlign="right"
                        w="33%"
                      >
                        {column.render('Header')}
                      </STh>
                      <STh />
                    </React.Fragment>
                  );
                }
                return (
                  <STh {...column.getHeaderProps()} key={index}>
                    {column.render('Header')}
                  </STh>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <STr
                {...row.getRowProps()}
                onClick={() => {
                  navigate(
                    SubseaRoutes.pool(
                      row.original.network,
                      row.original.vaultID,
                      row.original.poolID
                    )
                  );
                }}
              >
                {row.cells.map((cell, index) => {
                  if (index === 1) {
                    return (
                      <React.Fragment key={index}>
                        <STd {...cell.getCellProps()} textAlign="right">
                          {cell.render('Cell')}
                        </STd>
                        <STd />
                      </React.Fragment>
                    );
                  }
                  return (
                    <STd
                      {...cell.getCellProps()}
                      key={index}
                      borderLeft={
                        index === 0 ? '1px solid var(--border-purple)' : 'none'
                      }
                      borderRight={
                        index === 2 ? '1px solid var(--border-purple)' : 'none'
                      }
                    >
                      {cell.render('Cell')}
                    </STd>
                  );
                })}
              </STr>
            );
          })}
        </Tbody>
      </STable>
    </Box>
  );
};
