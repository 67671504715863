import { useMemo } from 'react';
import { TxExplorerLink } from '../components/TxExplorerLink';
import { ToastTitles } from '../constants/constants';
import { PoolWithVaultAndChain, SUPPORTED_REMOTE_CHAINS, useHeartbeat, useSDK } from '../contexts';
import { useNotifications } from '../notifications';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { Result } from '../utils/result';
import { waitForTxToMine } from '../utils/transaction';
import { makeTxExplorerLink } from '../utils/txNotifications';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { Chain, Hex } from 'viem';
import { getPublicClient, getWalletClient } from '@wagmi/core';
import { mainnet } from 'viem/chains';

const SDK_NOT_LOADED_MESSAGE = 'SDK not loaded in useDistressedAssetWithdraw';
const SIGNER_NOT_LOADED_MESSAGE = 'Signer not loaded in useDistressedAssetWithdraw';
const PROVIDER_NOT_LOADED_MESSAGE = 'Provider not loaded in useDistressedAssetWithdraw';
const POOL_NOT_LOADED_MESSAGE = 'Pool not loaded in useDistressedAssetWithdraw';


export function useDistressedAssetWithdraw(pool: Maybe<PoolWithVaultAndChain>, amount: bigint, proof: Hex[]) {
  const sdks = useSDK();
  const heartbeat = useHeartbeat();
  const notifications = useNotifications();

  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain: currentChain } = useNetwork();

  const sdk = useMemo(
    () => pool.flatMap((p) => Maybe.from(sdks?.get(p.vault.chain))),
    [pool, sdks]
  );

  return {
    async perform() {
      try {
        const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);
        const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
        const remoteChainId = _pool.config.remoteProtectedToken.chainId;

        if (currentChain?.id !== remoteChainId) {
          await switchNetworkAsync?.(remoteChainId);
        }

        const _signer = await getWalletClient({ chainId: remoteChainId });
        if (!_signer) throw new Error(SIGNER_NOT_LOADED_MESSAGE);

        const _publicClient = getPublicClient();
        if (!_publicClient) throw new Error(PROVIDER_NOT_LOADED_MESSAGE);

        const tx = _sdk.txFactory.distressedAssetWithdraw({
          vault: _pool.vault.addr,
          poolId: _pool.id,
          amount,
          proof,
        });
  
        const txHash = await tx.execute(_signer);
        const txChain = SUPPORTED_REMOTE_CHAINS.find((c) => (c as Chain).id === remoteChainId) ?? mainnet; // put mainnet as default value
        const txHashLink = makeTxExplorerLink(txChain, txHash);
        await waitForTxToMine(_publicClient, txHash);
        notifications.success(
          ToastTitles.WithdrawProtectedTokens.successMsg,
          txHashLink
            .map((l) => (
              <TxExplorerLink
                explorerName={l.name}
                explorerUrl={l.url}
              />
            ))
            .getOrElse(() => <></>)
        );

        heartbeat.sendPulse();
        return Result.ok(txHash);
      } catch (err) {
        notifications.error(
          ToastTitles.WithdrawProtectedTokens.errorMsg,
          'Check console for errors'
        );
        Logger.error('Withdraw protected tokens error:', err);
        return Result.error(err as Error);
      }
    }
  };
}
