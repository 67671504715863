import { DistressedAsset, PoolDefaultStatus } from '@risk-harbor/subsea-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  PoolStatus,
  VaultStatus,
  UnderwritingPositionType,
} from '../constants/constants';
import { CMSDataContextValue, useCMSData } from '../contexts/CMSData';
import {
  OnChainDataContextValue,
  PoolWithVaultAndChain,
  useOnChainData,
  VaultWithChain,
} from '../contexts/OnChainData';
import { ClaimPosition, DistressedAssetPosition, PolicyholderPosition, UnderwriterPosition } from '../types/types';
import { Maybe } from '../utils/maybe';
import { useAccount } from 'wagmi';
import { useSDK } from './SDK';
import { FiledClaim } from '@risk-harbor/subsea-sdk/dist/models/domain/Claim';
import { Time } from '../utils/time';

export type PortfolioPageContextValue = {
  policyholderPositions: PolicyholderPosition[];
  underwriterPositions: {
    standardPositions: UnderwriterPosition[];
    rolloverPositions: UnderwriterPosition[];
  };
  distressedAssetPositions: DistressedAssetPosition[];
  claimPositions: ClaimPosition[];
};

export const PortfolioPageContext = createContext<
  Maybe<PortfolioPageContextValue>
>(Maybe.none());

function getPoolStatus(pool: PoolWithVaultAndChain, expiry: Date) {
  const hacked = pool.state.defaultStatus === PoolDefaultStatus.Default;
  const paused = pool.vault.base.state.isPaused;
  const expired = expiry.getTime() < Date.now();
  if (hacked) return PoolStatus.Hacked;
  if (paused) return PoolStatus.Paused;
  return expired ? PoolStatus.Closed : PoolStatus.Open;
}

function getVaultStatus(vault: VaultWithChain, expiry: Date) {
  const paused = vault.base.state.isPaused;
  const expired = expiry.getTime() < Date.now();
  if (paused) return VaultStatus.Paused;
  return expired ? VaultStatus.Expired : VaultStatus.Open;
}

function validPositionBalance(balance: string | undefined): boolean {
  return !!balance && BigInt(balance) > BigInt(0);
}

function getUnderwriterPositions(
  onChainData: Maybe<OnChainDataContextValue>,
  cmsData: Maybe<CMSDataContextValue>,
  positionType: UnderwritingPositionType
): UnderwriterPosition[] {
  return onChainData
    .map((d) =>
      d.vaults
        .filter((vault) =>
          positionType === UnderwritingPositionType.Standard
            ? validPositionBalance(
                vault.base.state.underwritingPosition.standard.balance
              )
            : validPositionBalance(
                vault.base.state.underwritingPosition.rollover.balance
              )
        )
        .map((v) => {
          const cmsVault = cmsData.flatMap((data) =>
            Maybe.from(data.vaults.find((vault) => vault.address === v.addr))
          );
          const expiry = new Date(v.base.config.expiration * 1e3);
          const position: UnderwriterPosition = {
            vaultID: v.addr,
            underwritingTokenInfo: {
              address: v.base.config.underwritingToken.address,
              symbol: v.base.config.underwritingToken.symbol,
              decimals: v.base.config.underwritingToken.decimals,
            },
            shares:
              positionType === UnderwritingPositionType.Standard
                ? v.base.state.underwritingPosition.standard.balance! // eslint-disable-line
                : v.base.state.underwritingPosition.rollover.balance!, // eslint-disable-line
            network: v.chain.name,
            networkId: v.chain.id,
            name: cmsVault.map((vaultData) => vaultData.name).getOrElse(String),
            status: getVaultStatus(v, expiry),
            expiry,
            vault: v,
          };
          return position;
        })
    )
    .getOrElse(Array);
}

export function usePortfolioPageContext() {
  return useContext(PortfolioPageContext);
}

export const PortfolioPageContextProvider: React.FC<any> = ({ children }) => {
  const { address } = useAccount();
  const sdks = useSDK();
  const onChainData = useOnChainData();
  const cmsData = useCMSData();
  const [value, setValue] = useState<Maybe<PortfolioPageContextValue>>(
    Maybe.none()
  );
  const [pendingClaims, setPendingClaims] = useState<FiledClaim[]>([]);
  const [distressedAssets, setDistressedAssets] = useState<DistressedAsset[]>([]);

  useEffect(() => {
    if (!address || !sdks) return;
    const fetchAllPendingClaims = async () => {
      const allPendingClaims: FiledClaim[] = [];
      for (const sdk of sdks.values()) {
        const chainPendingClaims = await sdk.querier.fetchAllPendingClaims(address);
        allPendingClaims.push(...chainPendingClaims);
      }
      setPendingClaims(allPendingClaims);
    }
  
    fetchAllPendingClaims();
  }, [address, sdks]);

  useEffect(() => {
    if (!address || !sdks) return;
    const fetchDistAssets = async () => {
      const expiredVaultExists = onChainData
        .map((d) =>
          d.vaults
            .filter((v) =>
              Time.fromEvmTimestamp(v.base.config.expiration).isBefore(Time.now)
            )
        ).getOrElse(Array);
      if (expiredVaultExists.length === 0) return;

      const userDistAssets: DistressedAsset[] = [];
      for (const sdk of sdks.values()) {
        const chainDistAssets = await sdk.querier.fetchDistressedAssets(address);
        userDistAssets.push(...chainDistAssets);
      }
      setDistressedAssets(userDistAssets);
    }

    fetchDistAssets();
  }, [address, sdks, onChainData])

  useEffect(() => {
    const cleanup = () => {
      setValue(Maybe.none());
    };

    if (!address) {
      setValue(Maybe.none());
    }

    const claimPositions = pendingClaims.map((c) => {
      const pool = onChainData
        .map((d) => d.pools
        .find((p) => c.poolId === p.id && c.vaultAddr === p.vault.addr))
        .getOrElse(() => { return {} as PoolWithVaultAndChain});
      if (pool) {
        const expiry = new Date(pool.vault.base.config.expiration * 1e3);
        return {
          vaultID: pool.vault.addr,
          poolID: pool.id,
          claimID: Number(c.id),
          protectedTokenInfo: {
            symbol: pool.config.remoteProtectedToken.symbol,
            decimals: pool.config.remoteProtectedToken.decimals,
          },
          protectedAmount: c.protectedAmount,
          network: pool.vault.chain.name,
          status: PoolStatus.Hacked,
          expiry,
          pool,
        }
      }
      return null;
    }).filter((elem) => elem !== null) as ClaimPosition[];

    const distressedAssetPositions = distressedAssets.map((d) => {
      const pool = onChainData
        .map((data) => data.pools
        .find((p) => d.poolID === p.id && d.vaultID === p.vault.addr))
        .getOrElse(() => { return {} as PoolWithVaultAndChain});

      if (pool) {
        const cmsPool = cmsData.flatMap((data) =>
          Maybe.from(
            data.pools.find(
              (poolData) =>
                poolData.vault.address ===  pool.vault.addr &&
                poolData.poolId === pool.id
            )
          )
        );
        return {
          vaultID: pool.vault.addr,
          poolID: pool.id,
          poolName: cmsPool.map((poolData) => poolData.name).getOrElse(String),
          protectedTokenInfo: {
            symbol: pool.config.remoteProtectedToken.symbol,
            decimals: pool.config.remoteProtectedToken.decimals
          },
          amount: d.amount,
          proof: d.proof,
          network: pool.vault.chain.name,
          pool,
        };
      }
      return null;
    }).filter((elem) => elem !== null) as DistressedAssetPosition[];

    const policyholderPositions = onChainData
      .map((d) =>
        d.pools
          .filter((pool) =>
            validPositionBalance(pool.state.protectionPositionToken.balance)
          )
          .map((p) => {
            const cmsPool = cmsData.flatMap((data) =>
              Maybe.from(
                data.pools.find(
                  (poolData) =>
                    poolData.vault.address === p.vault.addr &&
                    poolData.poolId === p.id
                )
              )
            );
            const expiry = new Date(p.vault.base.config.expiration * 1e3);
            const position: PolicyholderPosition = {
              vaultID: p.vault.addr,
              poolID: p.id,
              protectedTokenInfo: {
                symbol: p.config.remoteProtectedToken.symbol,
                decimals: p.config.remoteProtectedToken.decimals,
              },
              // eslint-disable-next-line
              protectedTokenAmount: p.state.protectionPositionToken.balance!,
              network: p.vault.chain.name,
              name: cmsPool.map((poolData) => poolData.name).getOrElse(String),
              status: getPoolStatus(p, expiry),
              expiry,
              pool: p,
            };
            return position;
          })
      )
      .getOrElse(Array);

    const standardPositions = getUnderwriterPositions(
      onChainData,
      cmsData,
      UnderwritingPositionType.Standard
    );
    const rolloverPositions = getUnderwriterPositions(
      onChainData,
      cmsData,
      UnderwritingPositionType.Rollover
    );

    setValue(
      Maybe.some({
        policyholderPositions,
        underwriterPositions: {
          standardPositions,
          rolloverPositions,
        },
        claimPositions,
        distressedAssetPositions,
      })
    );

    return cleanup;
  }, [onChainData, cmsData, address, pendingClaims, distressedAssets]);

  return (
    <PortfolioPageContext.Provider value={value}>
      {children}
    </PortfolioPageContext.Provider>
  );
};
