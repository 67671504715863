/* eslint-disable no-await-in-loop */
import { Hash, PublicClient } from "viem";

export async function waitForTxToMine(
    client: PublicClient,
    txHash: Hash
): Promise<void> {
    // @ts-ignore
    await client.waitForTransactionReceipt({
        hash: txHash,
        confirmations: 1,
    });
}
