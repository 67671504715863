import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import styled from 'styled-components';
import { useAccount } from 'wagmi';
import { OnboardingModal } from '../Modals/OnboardingModal';
import { AccountInfoModal } from '../Modals/AccountInfoModal';

const FlexBox = styled.div`
  display: flex;

  @media only screen and (max-width: 1196px) {
    margin-top: 2.5rem;
    flex-direction: column;
    row-gap: 1rem;
    width: 200px;
    margin: 1.5rem auto auto auto;
  }
`;

const GreenDot = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  background: #32de84;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

const SButton = styled(Button)<{ isDisabled: boolean }>`
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  font-size: var(--h2-font-size) !important;
  text-align: center !important;
  font-weight: 600 !important;
  width: 100% !important;
  padding: 2rem 0rem !important;
  border-radius: 0rem 0rem 0.625rem 0.625rem !important;
  color: var(--text-primary-white) !important;
  background: var(--components-primary-purple) !important;
  border: 0.5px solid #7e9bd1 !important;

  &:hover {
    background-color: var(--components-primary-purple) !important;
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none;
  }
`;

interface Props {
  location?: string;
  onClick?: () => void;
}

export const ButtonConnect: React.FC<Props> = ({ location, onClick }) => {
  const {
    onOpen: onAccountOpen,
    isOpen: isAccountOpen,
    onClose: onAccountClose,
  } = useDisclosure();
  const {
    onOpen: onOnboardingOpen,
    isOpen: isOnboardingOpen,
    onClose: onOnboardingClose,
  } = useDisclosure();
  const { address, isConnected } = useAccount();

  return (
    <>
      {!isConnected && location === 'orderbox' ? ( // eslint-disable-line
        <SButton onClick={onClick}>Connect Wallet</SButton>
      ) : !isConnected && location === 'page' ? (
        <Button
          _hover={{
            textDecoration: 'none',
            filter: 'brightness(85%)',
          }}
          _active={{ color: 'transparent' }}
          _focus={{ outline: 'none', boxShadow: 'none' }}
          px="var(--button-px)"
          py="var(--button-py)"
          textColor="white"
          background="var(--components-primary-purple)"
          border="0.5px solid #7e9bd1"
          fontSize="var(--p-font-size)"
          onClick={onClick}
        >
          Connect Wallet
        </Button>
      ) : (
        <FlexBox>
          <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-purple)"
            border="0.5px solid #7e9bd1"
            fontSize="var(--p-font-size)"
            onClick={!isConnected ? onOnboardingOpen : onAccountOpen}
          >
            {!isConnected ? (
              `Connect Wallet`
            ) : (
              <>
                <GreenDot />
                {address?.substring(0, 6)}...{address?.substring(36, 42)}
              </>
            )}
          </Button>
          <AccountInfoModal isOpen={isAccountOpen} onClose={onAccountClose} />
          <OnboardingModal
            isOpen={isOnboardingOpen}
            onClose={onOnboardingClose}
          />
        </FlexBox>
      )}
    </>
  );
};

ButtonConnect.defaultProps = {
  location: '',
  onClick: () => {},
};
