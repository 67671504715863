import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Td, Tr, Tbody, Button, Spinner } from '@chakra-ui/react';
import { ClaimPosition } from '../../types/types';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import { useFileEvent } from '../../actions/fileEvent';
import { Maybe } from '../../utils/maybe';

const STr = styled(Tr)`
  background-color: var(--table-background-purple);
  height: 5rem;
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

interface Props {
  claimItem: ClaimPosition;
}

export const ClaimItem: React.FC<Props> = ({ claimItem }) => {
  const [loading, setLoading] = useState(false);
  const fileEvent = useFileEvent(
    Maybe.from(claimItem.pool),
    claimItem.claimID,
    claimItem.protectedAmount
  );

  return (
    <Tbody>
      <STr>
        <STd borderLeft="1px solid var(--border-purple)">
          <TokenSymbol>
            {decimalFormat(
              mapRawAmountToUi(
                claimItem.protectedAmount.toString(),
                claimItem.protectedTokenInfo.decimals
              ))}{' '}
              {claimItem.protectedTokenInfo.symbol}
          </TokenSymbol>
        </STd>
        <STd />
        <STd>{claimItem.network}</STd>
        <STd />
        <STd>{claimItem.status}</STd>
        <STd />
        <STd>{moment(claimItem.expiry.toUTCString()).format('MMM. D, YYYY')}</STd>
        <STd borderRight="1px solid var(--border-purple)">
          <Button
            isDisabled={claimItem.pool.vault.base.state.isPaused}
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="linear-gradient(96deg, #3654AA 0%, #5939CB 100%)"
            border="0.5px solid #7e9bd1"
            borderRadius="5px"
            fontSize="var(--p2-font-size)"
            onClick={async () => {
              setLoading(true);
              await fileEvent.finishClaim();
              setLoading(false);
            }}
            width="8rem"
          >
            {loading ? <Spinner /> : 'Finish Event'}
          </Button>
        </STd>
      </STr>
    </Tbody>
  );
}
