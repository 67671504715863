import { useState, useEffect } from 'react';

type AsyncFn<T> = () => Promise<T>;

export function useAsyncMemo<T>(
  fn: AsyncFn<T>,
  deps: readonly unknown[]
): T | undefined {
  const [state, setState] = useState<T | undefined>(undefined);

  useEffect(() => {
    let cancelled = false;

    async function fetchData() {
      const data = await fn();

      if (!cancelled) {
        setState(data);
      }
    }

    fetchData();

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
}
