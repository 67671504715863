import React, { useState } from 'react';
import styled from 'styled-components';
import { Td, Tr, Tbody, Button, Spinner } from '@chakra-ui/react';
import { DistressedAssetPosition } from '../../types/types';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import { Maybe } from '../../utils/maybe';
import { useDistressedAssetWithdraw } from '../../actions/distressedAssetWithdraw';

const STr = styled(Tr)`
  background-color: var(--table-background-purple);
  height: 5rem;
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

interface Props {
  daItem: DistressedAssetPosition;
}

export const DAItem: React.FC<Props> = ({ daItem }) => {
  const [loading, setLoading] = useState(false);

  const daWithdraw = useDistressedAssetWithdraw(
    Maybe.from(daItem.pool),
    daItem.amount,
    daItem.proof
  );

  return (
    <Tbody>
      <STr>
        <STd borderLeft="1px solid var(--border-purple)">
          <TokenSymbol>
            {decimalFormat(
              mapRawAmountToUi(
                daItem.amount.toString(),
                daItem.protectedTokenInfo.decimals
              ))}{' '}
              {daItem.protectedTokenInfo.symbol}
          </TokenSymbol>
        </STd>
        <STd>{daItem.network}</STd>
        <STd>{daItem.poolName}</STd>
        <STd borderRight="1px solid var(--border-purple)">
          <Button
            isDisabled={daItem.pool.vault.base.state.isPaused}
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="linear-gradient(96deg, #3654AA 0%, #5939CB 100%)"
            border="0.5px solid #7e9bd1"
            borderRadius="5px"
            fontSize="var(--p2-font-size)"
            onClick={async () => {
                setLoading(true);
                await daWithdraw.perform();
                setLoading(false);
              }}
            width="8rem"
          >
            {loading ? <Spinner /> : 'Withdraw'}
          </Button>
        </STd>
      </STr>
    </Tbody>
  );
}
