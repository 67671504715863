import React from 'react';
import styled from 'styled-components';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Maybe } from '../../utils/maybe';
import { PortfolioPageContextProvider, PortfolioPageContextValue, usePortfolioPageContext } from '../../contexts/Portfolio';
import { SubscriptionStatus, UnderwritingPositionType } from '../../constants/constants';
import { PolicyholderPositionItem, UnderwriterPositionItem } from '../../types/types';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import moment from 'moment';
import { SubseaRoutes } from '../../utils/routes';
import shield from '../../assets/transparent-logos/shield.svg';
import coin from '../../assets/transparent-logos/coin.svg';
// import { SubscriptionTabs } from './SubscriptionTabs';
import { ProtectionTable } from '../Tables/ProtectionTable';
import { PositionTable } from '../Tables/PositionTable';
import { ClaimTable } from '../Tables/ClaimTable';
import { DATable } from '../Tables/DATable';

const CustomTab = styled(Tab)`
  font-size: var(--h2-font-size) !important;
  color: var(--text-primary-white) !important;
  text-align: center !important;
  font-weight: 600 !important;

  &:hover {
    filter: brightness(80%);
  }

  &:active {
    background-color: transparent !important;
    border-color: var(--components-primary-purple) !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const Heading = styled.p`
  font-size: var(--h2-font-size);
  color: var(--text-primary-white);
  font-weight: 600;
`;

const Icon = styled.img`
  width: 1.625rem;
  margin-left: 0.5rem;
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin-top: 2rem;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

function getUnderwriterPositionItems(
  portfolioContext: Maybe<PortfolioPageContextValue>,
  positionType: UnderwritingPositionType
): UnderwriterPositionItem[] {
  return portfolioContext
    .map((d) =>
      (positionType === UnderwritingPositionType.Standard
        ? d.underwriterPositions.standardPositions
        : d.underwriterPositions.rolloverPositions
      ).map((position) => ({
        underwritingTokenInfo: {
          address: position.underwritingTokenInfo.address,
          symbol: position.underwritingTokenInfo.symbol,
        },
        shares: `${decimalFormat(
          mapRawAmountToUi(
            position.shares,
            position.underwritingTokenInfo.decimals
          )
        )}`,
        network: position.network,
        networkId: position.networkId,
        name: position.name,
        status: position.status,
        expiry: `${moment(position.expiry.toUTCString()).format(
          'MMM. D, YYYY'
        )}`,
        route: SubseaRoutes.vault(position.vault.chain.name, position.vaultID),
        subscriptionStatus:
          positionType === UnderwritingPositionType.Standard
            ? SubscriptionStatus.NotSubscribed
            : SubscriptionStatus.Subscribed,
        vault: position.vault,
      }))
    )
    .getOrElse(Array);
}

const PortfolioTabsInner: React.FC = () => {
  const portfolioPageContext = usePortfolioPageContext();

  const policyholderPositions: PolicyholderPositionItem[] = portfolioPageContext
    .map((d) =>
      d.policyholderPositions.map(
        (position): PolicyholderPositionItem => ({
          protectedTokenInfo: position.protectedTokenInfo,
          protectedTokenAmount: `${decimalFormat(
            mapRawAmountToUi(
              position.protectedTokenAmount,
              position.protectedTokenInfo.decimals
            )
          )}`,
          network: position.network,
          name: position.name,
          status: position.status,
          expiry: `${moment(position.expiry.toUTCString()).format(
            'MMM. D, YYYY'
          )}`,
          route: SubseaRoutes.pool(
            position.network,
            position.vaultID,
            position.poolID
          ),
          pool: position.pool,
        })
      )
    )
    .getOrElse(Array);

  const standardPositions = getUnderwriterPositionItems(
    portfolioPageContext,
    UnderwritingPositionType.Standard
  );
  // const rolloverPositions = getUnderwriterPositionItems(
  //   portfolioPageContext,
  //   UnderwritingPositionType.Rollover
  // );

  const claimPositions = portfolioPageContext.map((d) => d.claimPositions).getOrElse(Array);
  const distressedAssetPositions = portfolioPageContext
    .map((d) => d.distressedAssetPositions)
    .getOrElse(Array);

  return (
    <Tabs mb="5rem">
      <TabList borderColor="var(--background-purple)">
        <CustomTab mr="1rem" _selected={{ borderColor: '#5939CB' }}>
          Protection <Icon src={shield} alt="shield" />
        </CustomTab>
        <CustomTab  mr="1rem" _selected={{ borderColor: '#5939CB' }}>
          Investing
          <Icon src={coin} alt="coin" />
        </CustomTab>
      </TabList>

      <TabPanels>
        <TabPanel padding="0 !important">
          {policyholderPositions.length === 0 ? (
            <Text>You have not purchased any protection yet.</Text>
          ) : (
            <ProtectionTable items={policyholderPositions} name="portfolio" />
          )}
          <Box marginTop="8rem">
            <Heading>Pending Events</Heading>
            {claimPositions.length === 0 ? (
              <Text>You have no pending events to complete at this time.</Text>
            ) : (
              <ClaimTable items={claimPositions} name="portfolio" />
            )}
          </Box>
        </TabPanel>
        <TabPanel padding="0 !important">
          {/* TODO(zak): for later, when we re-enable rollover */}
          {/* {standardPositions.length === 0 && rolloverPositions.length === 0 ? ( */}
          {standardPositions.length === 0 ? (
            <Text>
              You have not invested in a vault or underwritten any positions
              yet.
            </Text>
          ) : (
            <PositionTable items={standardPositions} name="portfolio" />
          )}
          <Box marginTop="8rem">
            <Heading>Distressed Assets</Heading>
            {distressedAssetPositions.length === 0 ? (
              <Text>You have no protected tokens to withdraw at this time.</Text>
            ) : (
              <DATable items={distressedAssetPositions} name="portfolio" />
            )}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const PortfolioTabs: React.FC = () => {
  return (
    <PortfolioPageContextProvider>
      <PortfolioTabsInner />
    </PortfolioPageContextProvider>
  );
};
