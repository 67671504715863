/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
import { Fraction, Quote, ISubseaTxQuoter } from "@risk-harbor/subsea-sdk";
import { ERC20 } from "@risk-harbor/subsea-contracts";
import { useMemo, useState } from "react";
import {
    PoolWithVaultAndChain,
    useConfig,
    useHeartbeat,
    useSDK,
} from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { Tx, TxHash } from "../actions/shared";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { ToastTitles } from "../constants/constants";
import { getContract } from "@wagmi/core";
import { useUserBalances } from "../contexts/UserBalances";

type ProtectQuoter = ISubseaTxQuoter["protect"];

interface Protect extends Tx {
    setProtectAmount(val: FixedPointNumber): void;
    setSlippage(val: Fraction): void;
    quote(): Maybe<ReturnType<ProtectQuoter>>;
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in useProtect";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useProtect";
const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in useProtect";
const POOL_NOT_LOADED_MESSAGE = "Pool not loaded in useProtect";
const QUOTE_NOT_LOADED_MESSAGE = "Quote not loaded in useProtect";
const SDK_NOT_LOADED_MESSAGE = "SDK not loaded in useProtect";

export function useProtect(pool: Maybe<PoolWithVaultAndChain>): Protect {
    const [protectAmount, setProtectAmount] = useState<FixedPointNumber>(
        FixedPointNumber.zero
    );

    const { defaultSlippage } = useConfig();
    const [slippage, setSlippage] = useState<Fraction>();
    const sdks = useSDK();
    const sdk = pool.flatMap((p) => Maybe.from(sdks?.get(p.vault.chain)));
    const account = useAccount();
    const publicClient = usePublicClient();
    const heartbeat = useHeartbeat();
    const walletClient = useWalletClient();
    const notifications = useNotifications();
    const { refreshBalances } = useUserBalances();

    const quote = useMemo(
        () =>
            sdk.zip(pool).map(([_sdk, _pool]) =>
                _sdk.txQuoter.protect({
                    vault: _pool.vault.addr,
                    poolId: _pool.id,
                    amount: protectAmount.toBigInt(),
                    slippage: slippage ?? defaultSlippage,
                })
            ),
        [defaultSlippage, pool, protectAmount, sdk, slippage]
    );

    // TODO(jason): Handle insufficient balance here
    //              (but in actual flow button should be disabled and this code path should never run)

    const protectAction = new Action<TxHash>();
    // ERC20#approve
    protectAction
        .addStep({
            async call() {
                const s = Maybe.from(walletClient.data).required(
                    SIGNER_NOT_LOADED_MESSAGE
                );
                const p = pool.required(POOL_NOT_LOADED_MESSAGE);
                const q = quote.required(QUOTE_NOT_LOADED_MESSAGE);
                const undrErc20Addr =
                    p.vault.base.config.underwritingToken.address;
                const undrErc20 = getContract({
                    address: undrErc20Addr,
                    abi: ERC20.abi,
                    walletClient: s,
                });
                const txHash = await undrErc20.write.approve([
                    p.vault.addr,
                    FixedPointNumber.fromRaw(
                        q.output.maxPremium,
                        p.vault.base.config.underwritingToken.decimals
                    ).toBigInt(),
                ]);
                return txHash;
            },
            async waitForSatisfaction(txHash): Promise<void> {
                const p = Maybe.from(publicClient).required(
                    PROVIDER_NOT_LOADED_MESSAGE
                );
                const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);

                const txHashLink = makeTxExplorerLink(
                    _pool.vault.chain,
                    txHash
                );

                await waitForTxToMine(p, txHash);
                notifications.success(
                    "ERC20 Approval Successful",
                    txHashLink
                        .map((l) => (
                            <TxExplorerLink
                                explorerName={l.name}
                                explorerUrl={l.url}
                            />
                        ))
                        .getOrElse(() => <></>)
                );
            },
            async satisfied(): Promise<boolean> {
                const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
                const owner = Maybe.from(account.address).required(
                    WALLET_NOT_LOADED_MESSAGE
                );
                const undrErc20Addr =
                    _pool.vault.base.config.underwritingToken.address;

                const undrErc20 = getContract({
                    abi: ERC20.abi,
                    address: undrErc20Addr,
                });

                const _allowance = await undrErc20.read.allowance([
                    owner,
                    _pool.vault.addr,
                ]);
                const _satisfied = _allowance >= quote.required().output.maxPremium;
                return _satisfied;
            },
        })
        // RHVault#protect
        .addStep({
            async call() {
                const s = Maybe.from(walletClient.data).required(
                    SIGNER_NOT_LOADED_MESSAGE
                );
                const p = pool.required(POOL_NOT_LOADED_MESSAGE);
                const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);
                const tx = _sdk.txFactory.protect({
                    vault: p.vault.addr,
                    poolId: p.id,
                    amount: protectAmount.toBigInt(),
                    slippage: slippage ?? defaultSlippage,
                });

                const txHash = await tx.execute(s);
                heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after protect
                refreshBalances('karak'); // refresh user's karak balances after protect
                return txHash;
            },
            async waitForSatisfaction(txHash): Promise<void> {
                const p = Maybe.from(publicClient).required(
                    PROVIDER_NOT_LOADED_MESSAGE
                );
                const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
                const txHashLink = makeTxExplorerLink(
                    _pool.vault.chain,
                    txHash
                );
                await waitForTxToMine(p, txHash);
                notifications.success(
                    ToastTitles.Protect.successMsg,
                    txHashLink
                        .map((l) => (
                            <TxExplorerLink
                                explorerName={l.name}
                                explorerUrl={l.url}
                            />
                        ))
                        .getOrElse(() => <></>)
                );
            },
            async satisfied(): Promise<boolean> {
                // terminal step
                return false;
            },
        });

    return {
        setProtectAmount(val: FixedPointNumber): void {
            if (val.toBigInt() !== protectAmount.toBigInt()) {
                setProtectAmount(val);
            }
        },
        setSlippage(val: Fraction): void {
            if (
                val.numerator !== slippage?.numerator ||
                val.denominator !== slippage?.denominator
            ) {
                setSlippage(val);
            }
        },
        quote(): Maybe<Quote.Protect> {
            return quote;
        },
        async perform(): Promise<Result<TxHash, Error>> {
            let res: Result<TxHash, Error> = Result.error(
                new Error("Protect had 0 actions")
            );

            while (!protectAction.completed) {
                res = await protectAction.step();
                if (res.isError) {
                    Logger.error(
                        "Transaction Failed",
                        res.mapErr((e) => e).getOrElse(() => new Error())
                    );
                    notifications.error(
                        ToastTitles.Protect.errorMsg,
                        <Text>Check console for error</Text>
                    );
                    return res;
                }
            }

            return res;
        },
    };
}
