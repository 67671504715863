/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ToolTip } from '../ToolTip/ToolTip';
import { BLOCKED_ADDRESSES, VaultDetails } from '../../constants/constants';
import { TokenAmountInput } from '../OrderBox/TokenAmountInput';
import { OrderButton } from '../OrderBox/OrderButton';
// import { Switch } from '@chakra-ui/react';
import { Maybe } from '../../utils/maybe';
import { useVaultPageContext } from '../../contexts/VaultPage';
import { Time } from '../../utils/time';
import { FixedPointNumber } from '../../utils/fixedPoint';
import { calculateYield, checkZeroAmount } from '../../utils/helpers';
import { useDeposit } from '../../actions/deposit';
import { Logger } from '../../utils/logger';
import { PoolDefaultStatus } from '@risk-harbor/subsea-sdk';
import { useBlockedUser } from '../../contexts/BlockedUserContext';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-purple);
  border: 1px solid var(--border-purple);
  border-radius: 8px;
  margin-top: 0.5rem;
  // margin-bottom: 1rem;
  margin-bottom: 4.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const ExpiryText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p2-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-primary-white);
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;
  text-overflow: ellipsis;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const VFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onConnect: () => void;
}

export const Deposit: React.FC<Props> = ({ onConnect }) => {
  const vaultPageData = useVaultPageContext();
  const userBlocked = useBlockedUser();
  const account = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const vault = useMemo(
    () => vaultPageData.map((d) => d.vault),
    [vaultPageData]
  );

  const vaultNotExpired = vault
    .map((v) => v.onchain.base.config.expiration)
    .map((expiration) => Time.fromEvmTimestamp(expiration).isAfter(Time.now));

  const vaultChainId = vault.map((v) => v.onchain.chain.id).getOrElse(Number);

  const showDepositRewards = vault.map((v) =>
    v.onchain.pools.every(
      (p) => p.state.defaultStatus === PoolDefaultStatus.NoDefault
    )
  );

  // const vaultRolloverEnabed = vault.map((v) => v.onchain.base.config.rolloverEnabled);

  const [rolloverEnabled, setRolloverEnabled] = useState<boolean>(false); // eslint-disable-line
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());

  const undrToken = vault.map((v) => v.onchain.base.config.underwritingToken);

  const undrTokenBalRaw = undrToken
    .flatMap((t) => Maybe.from(t.balance))
    .map(BigInt);

  const undrTokenBalFixedPoint = undrToken
    .zip(undrTokenBalRaw)
    .map(([token, bal]) => FixedPointNumber.fromRaw(bal, token.decimals));

  const maxAmount = undrTokenBalFixedPoint.map((v) => v.toString()).string();

  const apy = vault.map((v) => calculateYield(v.onchain.base).toFixed(2));

  const deposit = useDeposit(vault.map((v) => v.onchain));

  useEffect(() => {
    deposit
      .zip(undrToken)
      .zip(amount)
      .map(([[_deposit, _token], _amount]) =>
        _deposit.setDepositAmount(
          FixedPointNumber.fromDecimal(_amount, _token.decimals)
        )
      );
  }, [amount, deposit, undrToken]);

  useEffect(() => {
    deposit.map((_deposit) => { // eslint-disable-line
      if (rolloverEnabled) {
        _deposit.enableRollover();
      } else {
        _deposit.disableRollover();
      }
    });
  }, [rolloverEnabled, deposit]);

  return (
    <>
      <Container>
        {vaultNotExpired.boolean() ? (
          <>
            <TokenAmountInput
              actionType="Deposit"
              token={vault
                .map((v) => v.onchain.base.config.underwritingToken.symbol)
                .string()}
              amount={amount.string()}
              maxAmount={maxAmount}
              onChange={(v) =>
                setAmount(v.trim() ? Maybe.from(v) : Maybe.none())
              }
            />
            <AmountBox>
              <VFlexBox>
                {showDepositRewards.boolean() ? (
                  <HFlexBox>
                    <Text>
                      Deposit Rewards
                      <ToolTip tooltipText={VaultDetails.DREW} width="100%" />
                    </Text>
                    <AmountText>{apy.map((v) => `${v}%`).string()}</AmountText>
                  </HFlexBox>
                ) : (
                  <></>
                )}
              </VFlexBox>
            </AmountBox>
            {/* <HFlexBox
              style={{
                marginBottom: '4rem',
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 'var(--p-font-size)',
                  color: 'var(--text-primary-white)',
                }}
              >
                Subscribe to Vault
                <ToolTip tooltipText={VaultDetails.SUB} />
              </Text>
              {vaultRolloverEnabed.boolean() && (
                <Switch
                  isChecked={rolloverEnabled}
                  size="md"
                  onChange={(e) => setRolloverEnabled(e.target.checked)}
                />
              )}
            </HFlexBox> */}
            <OrderButton
              actionType="Deposit"
              onConnect={onConnect}
              blockedUser={
                userBlocked.isBlocked ||
                BLOCKED_ADDRESSES.includes(account.address ?? '')
              }
              zeroAmount={checkZeroAmount(amount)}
              insufficientFunds={Boolean(
                Number(amount.string()) > Number(maxAmount)
              )}
              vaultPaused={vault
                .map((v) => v.onchain.base.state.isPaused)
                .getOrElse(Boolean)}
              onClick={async () => {
                if (chain && chain.id !== vaultChainId) {
                  await switchNetworkAsync?.(vaultChainId);
                }
                const _deposit = deposit.required('Deposit not ready');
                const res = await _deposit.perform();
                const txHash = res.required('Deposit not successful');
                Logger.info(`Deposit TX hash:`, txHash);
                return txHash;
              }}
            />
          </>
        ) : (
          <>
            <AmountBox style={{ marginBottom: 'var(--section-bm)' }}>
              <ExpiryText>
                The vault has expired. Deposit is unavailable at this time.
              </ExpiryText>
            </AmountBox>
            <OrderButton disabled actionType="Deposit" />
          </>
        )}
      </Container>
    </>
  );
};
