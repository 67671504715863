/* eslint-disable no-underscore-dangle */

import { Address } from '@risk-harbor/subsea-sdk';
import { getAddressFromString } from './address';
import { Maybe } from './maybe';
import { isValidUrl } from './url';

export class EnvLoader {
  constructor(private readonly env: NodeJS.ProcessEnv) {}

  loadStringMaybe(key: string): Maybe<string> {
    return Maybe.from(this.env[key]);
  }

  loadNumberMaybe(key: string): Maybe<number> {
    return Maybe.from(this.env[key]).flatMap((val) => {
      const valNum = Number.parseInt(val, 10);
      if (Number.isNaN(valNum)) {
        return Maybe.none();
      }

      return Maybe.some(valNum);
    });
  }

  loadBooleanMaybe(key: string): Maybe<boolean> {
    return Maybe.from(this.env[key]).flatMap((val) => {
      if (!['true', 'false'].includes(val)) {
        return Maybe.none();
      }

      return Maybe.some(val === 'true');
    });
  }

  loadAddressMaybe(key: string): Maybe<Address> {
    return Maybe.from(this.env[key]).flatMap((val) => {
      try {
        return Maybe.some(getAddressFromString(val));
      } catch {
        return Maybe.none();
      }
    });
  }

  loadUrlMaybe(key: string): Maybe<string> {
    return Maybe.from(this.env[key]).flatMap((val) => {
      if (isValidUrl(val)) {
        return Maybe.some(val);
      }

      return Maybe.none();
    });
  }

  loadString(key: string): string {
    return this.loadStringMaybe(key).required(
      `Environment variable ${key} is either not set or is not a valid string`
    );
  }

  loadBoolean(key: string): boolean {
    return this.loadBooleanMaybe(key).required(
      `Environment variable ${key} is either not set or is not a valid boolean`
    );
  }

  loadNumber(key: string): number {
    return this.loadNumberMaybe(key).required(
      `Environment variable ${key} is either not set or is not a valid number`
    );
  }

  loadAddress(key: string): Address {
    return this.loadAddressMaybe(key).required(
      `Environment variable ${key} is either not set or is not a valid EVM address`
    );
  }

  loadUrl(key: string): string {
    return this.loadUrlMaybe(key).required(
      `Environment variable ${key} is either not set or is not a valid URL`
    );
  }
}

const staticEnvLoader = new EnvLoader(process.env);

/* REACT_APP_ENV */

function validateReactAppEnv(
  val: string | undefined | null
): val is 'production' | 'staging' | 'development' {
  switch (val) {
    case 'production':
    case 'staging':
    case 'development':
      return true;
    default:
      return false;
  }
}

const _reactAppEnv = staticEnvLoader
  .loadStringMaybe('REACT_APP_ENV')
  .getOrElse(() => 'development');

if (!validateReactAppEnv(_reactAppEnv)) {
  throw new Error(
    `Invalid REACT_APP_ENV value ${_reactAppEnv}, expected one of ["production", "staging", "development", <nothing>]`
  );
}

export const REACT_APP_ENV = _reactAppEnv;

export function getBasedOnEnv<T>(map: Record<typeof REACT_APP_ENV, T>): T {
  return map[REACT_APP_ENV];
}

/* REACT_APP_WALLET_CONNECT_PROJECT_ID */
export const REACT_APP_WALLET_CONNECT_PROJECT_ID = staticEnvLoader.loadString(
  'REACT_APP_WALLET_CONNECT_PROJECT_ID'
);

/* REACT_APP_ALCHEMY_API_KEY */
export const REACT_APP_ALCHEMY_API_KEY = staticEnvLoader.loadString(
  'REACT_APP_ALCHEMY_API_KEY'
);

/* REACT_APP_INFURA_API_KEY */
export const REACT_APP_INFURA_API_KEY = staticEnvLoader.loadString(
  'REACT_APP_INFURA_API_KEY'
);

/* REACT_APP_TENDERLY_RPC_URL */
export const REACT_APP_TENDERLY_RPC_URL = staticEnvLoader.loadString(
  'REACT_APP_TENDERLY_RPC_URL'
);

/* REACT_APP_MERKLE_BANK_ADDRESS */
export const REACT_APP_MERKLE_BANK_ADDRESS = staticEnvLoader.loadString(
  'REACT_APP_MERKLE_BANK_ADDRESS'
);

/* REACT_APP_REWARDS_CHAIN */
export const REACT_APP_REWARDS_CHAIN = staticEnvLoader.loadString(
  'REACT_APP_REWARDS_CHAIN'
);
