/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Button } from '@chakra-ui/react';
import { TokenAmountInput } from './TokenAmountInput';
import { OrderButton } from './OrderButton';
import { ToolTip } from '../ToolTip/ToolTip';
import { usePoolPageContext } from '../../contexts/PoolPage';
import { useProtect } from '../../actions/protect';
import { RHFraction } from '../../utils/fraction';
import { Maybe } from '../../utils/maybe';
import { FixedPointNumber } from '../../utils/fixedPoint';
import {
  checkZeroAmount,
  decimalFormat,
  mapRawAmountToUi,
} from '../../utils/helpers';
import { Logger } from '../../utils/logger';
import { Transformer } from '../../utils/transformer';
import { BLOCKED_ADDRESSES } from '../../constants/constants';
import { useBlockedUser } from '../../contexts/BlockedUserContext';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// const SlippageText = styled.p`
//   font-size: var(--p-font-size);
//   color: var(--text-primary-white);
//   font-weight: 600;
//   margin-bottom: 0.5rem;
// `;

// const SDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   margin-bottom: 1.5rem;

//   @media only screen and (max-width: 499px) {
//     flex-direction: column;
//     margin-right: auto;
//     margin-bottom: 1.5rem;
//   }
// `;

// const SlippageContainer = styled.div`
//   display: flex;
//   flex-direction: row;

//   @media only screen and (max-width: 499px) {
//     margin-bottom: 1rem;
//   }
// `;

// const PresetSlippage = styled(Button)`
//   background-color: transparent !important;
//   border: 1px solid var(--border-purple) !important;
//   border-radius: 100px !important;
//   width: 4.5rem !important;
//   color: var(--text-primary-white) !important;
//   margin-right: 0.25rem !important;

//   &:hover {
//     background-color: transparent !important;
//     filter: brightness(80%);
//   }

//   &:focus {
//     box-shadow: none !important;
//   }

//   @media only screen and (max-width: 499px) {
//     &:hover {
//       filter: brightness(100%);
//     }
//   }
// `;

// const InputContainer = styled.div`
//   display: flex;
// `;

// const InputBox = styled(Input)``;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--section-bm);

  @media only screen and (max-width: 499px) {
    flex-direction: column;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: var(--background-purple);
  border: 1px solid var(--border-purple);
  border-radius: 8px;
  width: 48%;

  @media only screen and (max-width: 499px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 95%;
  text-overflow: ellipsis;
`;

const Description = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;
`;

interface Props {
  onConnect: () => void;
}

export const Protect: React.FC<Props> = ({ onConnect }) => {
  const poolPageData = usePoolPageContext();
  const userBlocked = useBlockedUser();
  const account = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const pool = poolPageData.map((d) => d.pool.onchain);
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());
  const [slippage, setSlippage] = useState<string>('0.1'); // eslint-disable-line
  // const regExp = /^(100|([1-9]?[0-9]?(\.[0-9]?)?))$/;
  const protect = useProtect(pool);

  const poolChainId = pool.map((p) => p.vault.chain.id).getOrElse(Number);

  const maxProtectableAmount = pool
    .map((p) => {
      const [capProt, utilProt] = [0, p.id]
        .map((i) => p.vault.base.state.allocationVector[i])
        .map(Transformer.undrToProt(p.config.payoutRatio));

      const remProt = capProt - utilProt;
      return mapRawAmountToUi(
        remProt.toString(),
        p.config.remoteProtectedToken.decimals
      );
    })
    .getOrElse(String);

  // const handleSlippageChange = (event: InputEvent): void => {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   const rawVal = (event.target as any)?.value || '';
  //   if (!regExp.test(rawVal)) {
  //     return;
  //   }

  //   setSlippage(rawVal);
  // };

  useEffect(() => {
    protect.setSlippage(RHFraction.fromPercentage(slippage).toFraction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slippage]);

  useEffect(() => {
    amount
      .zip(pool)
      .map(([_amount, _pool]) => {
        const val = protect.setProtectAmount(
          FixedPointNumber.fromDecimal(
            _amount,
            _pool.config.remoteProtectedToken.decimals
          )
        );

        return Maybe.some(val);
      })
      .orElse(() => {
        protect.setProtectAmount(FixedPointNumber.zero);
        return Maybe.none();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <>
      <Container>
        <TokenAmountInput
          actionType="Protect"
          token={pool.map((p) => p.config.remoteProtectedToken.symbol).string()}
          amount={amount.string()}
          maxAmount={maxProtectableAmount}
          onChange={(v) => setAmount(v.trim() ? Maybe.from(v) : Maybe.none())}
        />
        {account.address && (
          <p style={{ color: 'orange', fontSize: '0.85rem', marginBottom: '1rem' }}>
            Please note that purchasing protection does not earn you XP and you may lose some XP proportional to your premium.
          </p>
        )}
        {/* <SlippageText>Slippage Tolerance</SlippageText> */}
        {/* <SDiv>
          <SlippageContainer>
            <PresetSlippage onClick={() => setSlippage('0.1')}>
              0.1%
            </PresetSlippage>
            <PresetSlippage onClick={() => setSlippage('0.5')}>
              0.5%
            </PresetSlippage>
            <PresetSlippage onClick={() => setSlippage('1')}>1%</PresetSlippage>
          </SlippageContainer>
          <InputContainer>
            <InputBox
              className="protect-inputbox"
              placeholder="0"
              textAlign="right"
              _placeholder={{
                opacity: '0.7',
                color: 'var(--text-primary-white)',
              }}
              border="2px solid #281d45 !important"
              focusBorderColor="none"
              color="var(--text-secondary-gray)"
              value={slippage}
              onChange={(event: InputEvent) => handleSlippageChange(event)}
            />
            <Text style={{ marginBottom: 0, marginTop: 7 }}>%</Text>
          </InputContainer>
        </SDiv> */}
        <SubContainer>
          <ItemBox>
            <Text>
              {protect
                .quote()
                .zip(pool)
                .map(([_quote, _pool]) => {
                  const payoutUndrRaw = _quote.output.payout;
                  const undrToken = _pool.vault.base.config.underwritingToken;

                  return `${decimalFormat(
                    mapRawAmountToUi(
                      payoutUndrRaw.toString(),
                      undrToken.decimals
                    )
                  )} ${undrToken.symbol}`;
                })
                .string()}
            </Text>
            <Description>
              PAYOUT
              <ToolTip tooltipText="The amount paid out in tokens." />
            </Description>
          </ItemBox>
          <ItemBox>
            <Text>
              {protect
                .quote()
                .zip(pool)
                .map(([_quote, _pool]) => {
                  const premUndrRaw = _quote.output.premium;
                  const undrToken = _pool.vault.base.config.underwritingToken;

                  return `${decimalFormat(
                    mapRawAmountToUi(premUndrRaw.toString(), undrToken.decimals)
                  )} ${undrToken.symbol}`;
                })
                .string()}
            </Text>
            <Description>
              PREMIUM
              <ToolTip tooltipText="The cost of protection in tokens." />
            </Description>
          </ItemBox>
        </SubContainer>
        <OrderButton
          actionType="Protect"
          onConnect={onConnect}
          blockedUser={
            userBlocked.isBlocked ||
            BLOCKED_ADDRESSES.includes(account.address ?? '')
          }
          zeroAmount={checkZeroAmount(amount)}
          lowPremium={protect
            .quote()
            .zip(pool)
            .map(([_quote, _pool]) => {
              const premUndrRaw = _quote.output.premium;
              const undrToken = _pool.vault.base.config.underwritingToken;

              return (
                amount.string() &&
                Number(
                  mapRawAmountToUi(premUndrRaw.toString(), undrToken.decimals)
                ) < 0.005
              );
            })
            .boolean()}
          poolCapacityExceeded={Boolean(
            Number(amount.string()) > Number(maxProtectableAmount)
          )}
          insufficientFunds={protect
            .quote()
            .zip(pool)
            .map(([_quote, _pool]) => {
              const premUndrRaw = _quote.output.premium;
              const undrTokenBalRaw =
                _pool.vault.base.config.underwritingToken.balance;

              return Boolean(
                undrTokenBalRaw && premUndrRaw > BigInt(undrTokenBalRaw)
              );
            })
            .boolean()}
          vaultPaused={pool
            .map((p) => p.vault.base.state.isPaused)
            .getOrElse(Boolean)}
          onClick={async () => {
            if (chain && chain.id !== poolChainId) {
              await switchNetworkAsync?.(poolChainId);
            }
            const res = await protect.perform();
            const txHash = res.required('Protect not successful');
            Logger.info(`Protect TX hash:`, txHash);
            return txHash;
          }}
        />
      </Container>
    </>
  );
};
