/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { usePoolPageContext } from "../../contexts/PoolPage";
import { useFileEvent } from "../../actions/fileEvent";
import { FixedPointNumber } from "../../utils/fixedPoint";
import { Maybe } from "../../utils/maybe";
import { Box } from "@chakra-ui/react";
import { ToolTip } from "../ToolTip/ToolTip";
import {
    decimalFormat,
    mapRawAmountToUi,
    zeroAmountFallback,
} from "../../utils/helpers";
import { OrderButton } from "./OrderButton";
import { TokenAmountInput } from "./TokenAmountInput";
import { Chain, useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import {
    SUPPORTED_REMOTE_CHAINS,
} from "../../contexts";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const AmountBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--background-purple);
    border: 1px solid var(--border-purple);
    border-radius: 8px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: 499px) {
        flex-direction: column-reverse;
    }
`;

const Text = styled.p`
    font-size: var(--h3-font-size);
    color: var(--text-secondary-gray);
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 499px) {
        font-size: var(--p-font-size);
    }
`;

const AmountText = styled.p`
    font-size: var(--h3-font-size);
    color: var(--text-primary-white);

    @media only screen and (max-width: 499px) {
        font-size: var(--p-font-size);
    }
`;

interface Props {
    onConnect: () => void;
}

export const Claim: React.FC<Props> = ({ onConnect }) => {
    const poolPageContext = usePoolPageContext();
    const { address } = useAccount();
    const { switchNetworkAsync } = useSwitchNetwork();
    const pool = useMemo(
        () => poolPageContext.map((c) => c.pool.onchain),
        [poolPageContext]
    );

    const [loading, setLoading] = useState(false);

    const fileEvent = useFileEvent(pool);

    const protToken = pool.map((p) => p.config.remoteProtectedToken);
    const undrToken = pool.map((p) => p.vault.base.config.underwritingToken);

    const network = useNetwork();

    // const claimInProgress = useAsyncMemo(
    //     () => fileEvent.claimInProgress(),
    //     [fileEvent]
    // );

    const matchesNetwork = pool
        .map((p) =>
            p.config.remoteProtectedToken.chainId === network.chain?.id
        )
        .getOrElse(() => false);

    const expectedChain = pool.flatMap((p) =>
        Maybe.from(
            SUPPORTED_REMOTE_CHAINS.find(
                (c) => (c as Chain).id === p.config.remoteProtectedToken.chainId
            )
        )
    );

    const expectedChainId = expectedChain
      .map((c) => (c as any).id as number)
      .getOrElse(Number);

    function displayRawProtAmount(amt: Maybe<string | bigint>): string {
        return amt
            .zip(protToken)
            .flatMap(([_amt, _token]) => {
                const _amtFp = FixedPointNumber.fromRaw(
                    BigInt(_amt),
                    _token.decimals
                );

                if (_amtFp.toBigInt() === BigInt(0)) {
                    return Maybe.none();
                }

                return Maybe.some(_amtFp.toString());
            })
            .string();
    }

    return (
        <Container>
            <>
                <TokenAmountInput
                    disabled={loading}
                    actionType="Claim"
                    token={protToken.map((t) => t.symbol).string()}
                    amount={displayRawProtAmount(
                        fileEvent.claimQuote().map((q) => q.input.amount)
                    )}
                    maxAmount={displayRawProtAmount(fileEvent.maxAmount())}
                    onChange={fileEvent.setAmountToClaim}
                />
                <Box
                    display="flex"
                    flexDir="column"
                    w="100%"
                    style={{ marginBottom: "var(--section-bm)" }}
                >
                    <AmountBox>
                        <Text>
                            PAYOUT
                            <ToolTip tooltipText="The amount paid out in underwriting tokens." />
                        </Text>
                        <AmountText>
                            {fileEvent
                                .claimQuote()
                                .zip(undrToken)
                                .map(([_quote, _undr]) => {
                                    const payout = _quote.output.payoutAmount;
                                    const { decimals, symbol } = _undr;

                                    return `${decimalFormat(
                                        mapRawAmountToUi(
                                            payout.toString(),
                                            decimals
                                        )
                                    )} ${symbol}`;
                                })
                                .string()}
                        </AmountText>
                    </AmountBox>
                </Box>
                <OrderButton
                    onConnect={onConnect}
                    actionType="StartClaim"
                    disabled={loading}
                    insufficientFunds={fileEvent
                        .claimQuote()
                        .map(
                            (q) =>
                                q.input.amount >
                                BigInt(
                                    fileEvent
                                        .maxAmount()
                                        .getOrElse(zeroAmountFallback)
                                )
                        )
                        .getOrElse(() => true)}
                    vaultPaused={pool
                        .map((p) => p.vault.base.state.isPaused)
                        .getOrElse(Boolean)}
                    zeroAmount={fileEvent
                        .claimQuote()
                        .map((q) => q.input.amount === BigInt(0))
                        .getOrElse(() => true)}
                    onClick={async () => {
                        setLoading(true);
                        if (address && !matchesNetwork) {
                          await switchNetworkAsync?.(expectedChainId);
                        }
                        await fileEvent.startClaim();
                        setLoading(false);
                    }}
                />
            </>
        </Container>
    );
};
