import React from 'react';
import styled from 'styled-components';
import { Banner } from '../components/Banner/Banner';
import { OrderBox } from '../components/OrderBox';
import { InfoDesc } from '../components/PoolVaultInfo/InfoDesc';
import {
  PoolPageContextProvider,
  usePoolPageContext,
} from '../contexts/PoolPage';
import {
  policyholderRisksFallback,
  poolDescriptionFallback,
} from '../utils/cmsfallbacks';

const Section = styled.div`
  display: flex;
  column-gap: 2rem !important;

  @media only screen and (max-width: 1149px) {
    flex-direction: column;
    column-gap: 0rem !important;
  }
`;

const SubSection = styled.div`
  width: 54%;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

const PoolInner: React.FC = () => {
  const poolPageData = usePoolPageContext();

  return (
    <>
      <Banner type="protection" />
      <Section>
        <SubSection>
          <InfoDesc
            type="pool"
            description={poolPageData
              .flatMap((d) => d.pool.cms.map((p) => p.description))
              .getOrElse(poolDescriptionFallback)}
            risksDesc={poolPageData
              .flatMap((d) => d.pool.cms.map((p) => p.policyholderRisks))
              .getOrElse(policyholderRisksFallback)}
          />
        </SubSection>
        <OrderBox
          type="pool"
        />
      </Section>
    </>
  );
};

export const Pool: React.FC = () => {
  return (
    <PoolPageContextProvider>
      <PoolInner />
    </PoolPageContextProvider>
  );
};
