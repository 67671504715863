import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { OnboardingModal } from '../components/Modals/OnboardingModal';
import { ConnectEmptyState } from './ConnectEmptyState';
import { PortfolioTabs } from '../components/Portfolio/PortfolioTabs';
import { useAccount } from 'wagmi';

export const Portfolio: React.FC = () => {
  const account = useAccount();
  const {
    onOpen: onOnboardingOpen,
    isOpen: isOnboardingOpen,
    onClose: onOnboardingClose,
  } = useDisclosure();
  return (
    <>
      {account.address ? (
        <PortfolioTabs />
      ) : (
        <ConnectEmptyState onClick={onOnboardingOpen} />
      )}
      <OnboardingModal isOpen={isOnboardingOpen} onClose={onOnboardingClose} />
    </>
  );
};
