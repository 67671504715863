import { ISubseaTxQuoter, Quote, TxHash } from "@risk-harbor/subsea-sdk";
import { Maybe } from "../utils/maybe";
import { Result } from "../utils/result";
import {
    PoolWithVaultAndChain,
    SUPPORTED_LOCAL_CHAINS,
    SUPPORTED_REMOTE_CHAINS,
    useHeartbeat,
    useSDK,
} from "../contexts";
import { useAccount, useSwitchNetwork, useNetwork, useWalletClient } from "wagmi";
import { useNotifications } from "../notifications";
import { useMemo, useState } from "react";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Action, Step } from "../utils/action";
import { createPublicClient, getContract, http } from "viem";
import { ERC20, LocalDefaultector } from "@risk-harbor/subsea-contracts";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { waitForTxToMine } from "../utils/transaction";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { ToastTitles } from "../constants/constants";
import { Logger } from "../utils/logger";
import Decimal from "decimal.js";
import { Transformer } from "../utils/transformer";
import { zeroAmountFallback } from "../utils/helpers";
import { getWalletClient } from "@wagmi/core";

type ClaimQuoter = ISubseaTxQuoter["startClaim"];

export enum FinishClaimStatus {
    NULL = "NULL",
    IN_PROGRESS = "IN PROGRESS",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
}

interface FileEvent {
    setAmountToClaim(decStr: string): void;
    maxAmount(): Maybe<string>;
    claimQuote(): Maybe<ReturnType<ClaimQuoter>>;
    startClaim(): Promise<Result<TxHash, Error>>;
    finishClaim(): Promise<Result<TxHash, Error>>;
}

const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useFileEvent";
const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in useFileEvent";
const POOL_NOT_LOADED_MESSAGE = "Pool not loaded in useFileEvent";
const SDK_NOT_LOADED_MESSAGE = "SDK not loaded in useFileEvent";
const LOCAL_CHAIN_NOT_SUPPORTED = (id: number) =>
    `Local chain with ID ${id} not supported`;
const REMOTE_CHAIN_NOT_SUPPORTED = (id: number) =>
    `Remote chain with ID ${id} not supported`;

export function useFileEvent(pool: Maybe<PoolWithVaultAndChain>, claimId?: number, finishClaimAmount?: bigint): FileEvent {
    // Param
    const remoteProtectedToken = pool.map((p) => p.config.remoteProtectedToken);
    const policyToken = pool.map((p) => p.state.protectionPositionToken);

    // Composing hooks
    const account = useAccount();
    const sdks = useSDK();
    const walletClient = useWalletClient();
    const heartbeat = useHeartbeat();
    const notifications = useNotifications();

    const { switchNetworkAsync } = useSwitchNetwork();
    const { chain: currentChain } = useNetwork();

    // useState
    const [amount, setAmount] = useState<FixedPointNumber>(
        FixedPointNumber.zero
    );

    // useMemo
    const user = useMemo(() => Maybe.from(account.address), [account.address]);
    const sdk = useMemo(
        () => pool.flatMap((p) => Maybe.from(sdks?.get(p.vault.chain))),
        [pool, sdks]
    );
    const claimQuote = useMemo(
        () =>
            sdk.zip(pool).map(([_sdk, _pool]) =>
                _sdk.txQuoter.startClaim({
                    vault: _pool.vault.addr,
                    poolId: _pool.id,
                    amount: amount.toBigInt(),
                })
            ),
        [amount, pool, sdk]
    );

    // Actions
    const protApprovalStep: Step<TxHash> = {
        async call() {
            const _walletClient = Maybe.from(walletClient.data).required(
                SIGNER_NOT_LOADED_MESSAGE
            );
            const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);
            const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);

            const remoteChainId = _pool.config.remoteProtectedToken.chainId;

            if (_walletClient.chain.id !== remoteChainId) {
                throw new Error(
                    `Wallet clain chain ID does not match remote chain ID ${remoteChainId}`
                );
            }

            const txHashRecord = await _sdk.txFactory
                .startClaim({
                    vault: _pool.vault.addr,
                    poolId: _pool.id,
                    amount: amount.toBigInt(),
                })
                .setup?.(_walletClient);

            if (!txHashRecord || !txHashRecord["approve"]) {
                throw new Error("Approve step not included in SDK start file event");
            }

            return txHashRecord["approve"];
        },
        async waitForSatisfaction(txHash): Promise<void> {
            const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);

            const remoteChainId = _pool.config.remoteProtectedToken.chainId;
            const remoteChain = Maybe.from(
                SUPPORTED_REMOTE_CHAINS.find((c) => c.id === remoteChainId)
            ).required(REMOTE_CHAIN_NOT_SUPPORTED(remoteChainId));
            const remotePublicClient = createPublicClient({
                transport: http(),
                chain: remoteChain,
            });

            const txHashLink = makeTxExplorerLink(remoteChain, txHash);
            await waitForTxToMine(remotePublicClient, txHash);
            notifications.success(
                "Approval TX Successful",
                txHashLink
                    .map((l) => (
                        <TxExplorerLink
                            explorerName={l.name}
                            explorerUrl={l.url}
                        />
                    ))
                    .getOrElse(() => <></>)
            );
        },
        async satisfied(): Promise<boolean> {
            try {
                const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
                const _user = user.required(WALLET_NOT_LOADED_MESSAGE);

                const localChainId =
                    _pool.vault.base.config.underwritingToken.chainId;
                const localChain = Maybe.from(
                    SUPPORTED_LOCAL_CHAINS.find((c) => c.id === localChainId)
                ).required(LOCAL_CHAIN_NOT_SUPPORTED(localChainId));
                const localPublicClient = createPublicClient({
                    transport: http(),
                    chain: localChain,
                });

                const remoteChainId = _pool.config.remoteProtectedToken.chainId;
                const remoteChain = Maybe.from(
                    SUPPORTED_REMOTE_CHAINS.find((c) => c.id === remoteChainId)
                ).required(REMOTE_CHAIN_NOT_SUPPORTED(remoteChainId));
                const remotePublicClient = createPublicClient({
                    transport: http(),
                    chain: remoteChain,
                });

                // @ts-ignore
                const remoteProtToken = getContract({
                    abi: ERC20.abi,
                    address: _pool.config.remoteProtectedToken.address,
                    publicClient: remotePublicClient,
                });

                // @ts-ignore
                const localDefaultector = getContract({
                    abi: LocalDefaultector.abi,
                    address: _pool.config.localDefaultector,
                    publicClient: localPublicClient,
                });

                const satelliteDefaultectorAddr =
                    await localDefaultector.read.remoteDefaultector();

                const protTokenAllowance = await remoteProtToken.read.allowance(
                    [_user, satelliteDefaultectorAddr]
                );

                const isAllowanceSufficient =
                    protTokenAllowance >= amount.toBigInt();

                return isAllowanceSufficient;
            } catch (err) {
                return false;
            }
        },
    };
    const makeStartClaimStep: (callable: boolean) => Step<TxHash> = (
        callable
    ) => ({
        async call() {
            if (!callable) {
                throw new Error(
                    "Start file event not callable. Likely because its called in cancel file event."
                );
            }

            const _signer = Maybe.from(walletClient.data).required(
                SIGNER_NOT_LOADED_MESSAGE
            );
            const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
            const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);

            if (amount.toBigInt() === BigInt(0)) {
                throw new Error("Cannot start file event with 0 amount");
            }

            const tx = _sdk.txFactory.startClaim({
                vault: _pool.vault.addr,
                poolId: _pool.id,
                amount: amount.toBigInt(),
            });

            const txHash = await tx.execute(_signer);
            return txHash;
        },
        async waitForSatisfaction(txHash): Promise<void> {
            const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);

            const remoteChainId = _pool.config.remoteProtectedToken.chainId;
            const remoteChain = Maybe.from(
                SUPPORTED_REMOTE_CHAINS.find((c) => c.id === remoteChainId)
            ).required(REMOTE_CHAIN_NOT_SUPPORTED(remoteChainId));
            const remotePublicClient = createPublicClient({
                transport: http(),
                chain: remoteChain,
            });

            const txHashLink = makeTxExplorerLink(remoteChain, txHash);
            await waitForTxToMine(remotePublicClient, txHash);
            notifications.success(
                ToastTitles.StartClaim.successMsg,
                txHashLink
                    .map((l) => (
                        <TxExplorerLink
                            explorerName={l.name}
                            explorerUrl={l.url}
                        />
                    ))
                    .getOrElse(() => <></>)
            );
        },
        async satisfied(): Promise<boolean> {
            // terminal
            return false;
        },
    });

    const finishClaimStep: Step<TxHash> = {
        async call() {
          const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
          const localChainId =
          _pool.vault.base.config.underwritingToken.chainId;
          const _signer = await getWalletClient({ chainId: localChainId });

          if (!_signer) {
              throw new Error(WALLET_NOT_LOADED_MESSAGE);
          }
          const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);

          if (currentChain?.id !== localChainId) {
            await switchNetworkAsync?.(localChainId);
          }
          
          const tx = _sdk.txFactory.finishClaim({
            vault: _pool.vault.addr,
            poolId: _pool.id,
            claimId: claimId ?? 0,
            amount: finishClaimAmount ?? BigInt(0),
          });
  
          const txHash = await tx.execute(_signer);
          return txHash;
        },
        async waitForSatisfaction(txHash): Promise<void> {
          const _pool = pool.required(POOL_NOT_LOADED_MESSAGE);
  
          const localChainId =
          _pool.vault.base.config.underwritingToken.chainId;
          const localChain = Maybe.from(
              SUPPORTED_LOCAL_CHAINS.find((c) => c.id === localChainId)
          ).required(LOCAL_CHAIN_NOT_SUPPORTED(localChainId));
          const localPublicClient = createPublicClient({
              transport: http(),
              chain: localChain,
          });
  
          const txHashLink = makeTxExplorerLink(_pool.vault.chain, txHash);
          await waitForTxToMine(localPublicClient, txHash);
          notifications.success(
            ToastTitles.FinishClaim.successMsg,
            txHashLink
              .map((l) => (
                <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
              ))
              .getOrElse(() => <></>)
          );
        },
        async satisfied(): Promise<boolean> {
          // terminal
          return false;
        },
      };

    const startClaim = new Action<TxHash>("Start file event")
        .addStep(protApprovalStep)
        .addStep(makeStartClaimStep(true));

    const finishClaim = new Action<TxHash>("Finish file event")
        .addStep(finishClaimStep);

    async function performAction(
        action: Action<TxHash>
    ): Promise<Result<TxHash, Error>> {
        try {
            let res: Result<TxHash, Error> = Result.error(
                new Error(`${action.name} has 0 steps`)
            );
            while (!action.completed) {
                res = await action.step();
                if (res.isError) {
                    Logger.error(
                        "Transaction Failed",
                        res.mapErr((e) => e).getOrElse(() => new Error())
                    );
                    notifications.error(
                        `${action.name} failed`,
                        "Check console for logs"
                    );
                    return res;
                }
            }

            heartbeat.sendPulse();
            return res;
        } catch (err) {
            Logger.error(`Error during ${action.name}:`, err);
            // TODO(zak) update error notif here once start/finish claim is fixed.
            notifications.error(
                `${action.name} failed`,
                "Check console for logs"
            );
            return Result.error(err as Error);
        }
    }

    return {
        setAmountToClaim(decStr: string): void {
            remoteProtectedToken.map((t) => { // eslint-disable-line
                const fpAmount = FixedPointNumber.fromDecimal(
                    decStr,
                    t.decimals
                );
                if (fpAmount.toBigInt() !== amount.toBigInt()) {
                    setAmount(fpAmount);
                }
            });
        },
        maxAmount(): Maybe<string> {
            const capacityInProtected = new Decimal(
                pool
                    .map((p) =>
                        [p.vault.base.state.allocationVector[0]]
                            .map(Transformer.undrToProt(p.config.payoutRatio))
                            .toString()
                    )
                    .getOrElse(zeroAmountFallback)
            );
            const protectedTokenBalance = new Decimal(
                remoteProtectedToken
                    .flatMap((p) => Maybe.from(p.balance))
                    .getOrElse(zeroAmountFallback)
            );
            const policyPositionBalance = new Decimal(
                policyToken
                    .flatMap((p) => Maybe.from(p.balance))
                    .getOrElse(zeroAmountFallback)
            );

            return Maybe.some(
                Decimal.min(
                    capacityInProtected,
                    protectedTokenBalance,
                    policyPositionBalance
                ).toString()
            );
        },
        claimQuote(): Maybe<Quote.StartClaim> {
            return claimQuote;
        },
        startClaim(): Promise<Result<TxHash, Error>> {
            return performAction(startClaim);
        },
        finishClaim(): Promise<Result<TxHash, Error>> {
            return performAction(finishClaim);
        },
    };
}
