import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  // Center,
  Stack,
  Menu,
  MenuList,
  MenuItem,
  Checkbox,
  MenuButton,
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { VaultListItemView } from '../../types/types';
import {
  // ArrowBackIcon,
  // ArrowForwardIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';
import { useTable } from 'react-table';
import emptyTokenIcon from '../../assets/empty-token.png';
import { FilterStatus } from '../../constants/constants';
import { SearchNotFound } from '..//Search/SearchNotFound';
import { SearchBar } from '../Search/SearchBar';
import { getTokenSymbol } from '../../utils/helpers';
import { SubseaRoutes } from '../../utils/routes';
// import { Routes } from 'src/utils/routes';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-purple);
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const Image = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const TokenSymbol = styled.p`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VFlexbox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

// const PageSelector = styled.div`
//   float: right;
//   color: var(--text-primary-white);
//   font-weight: 600;
//   margin-top: 1rem;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;

//   @media only screen and (max-width: 960px) {
//     float: left;
//   }
// `;

// const PageMover = styled.span<{ disabled: boolean }>`
//   padding: 0.625rem;
//   color: ${(props) =>
//     props.disabled ? `#ffffff50 !important` : `none !important`};
//   &:hover {
//     cursor: pointer;
//   }
// `;

const SGrid = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
    column-gap: 0rem;
    row-gap: 0.5rem;
  }
`;

interface Props {
  vaults: VaultListItemView[];
}

const Token: React.FC<{ value: string }> = ({ value }) => {
  const [tokenIcon, tokenSymbol] = value.split('&');

  return (
    <HFlexbox>
      <Image
        src={tokenIcon}
        alt=""
        onError={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (e.target as any).src = emptyTokenIcon;
        }}
      />
      <VFlexbox>
        <TokenSymbol>{tokenSymbol}</TokenSymbol>
      </VFlexbox>
    </HFlexbox>
  );
};

const getVaultSearchInfo = (v: VaultListItemView, filter: string) => {
  return (
    v.name.toLowerCase().includes(filter.toLowerCase()) ||
    getTokenSymbol(v.underwritingToken)
      .toLowerCase()
      .includes(filter.toLowerCase()) ||
    v.network.toLowerCase().includes(filter.toLowerCase()) ||
    v.apy.toLowerCase().includes(filter.toLowerCase()) ||
    v.capacity.toLowerCase().includes(filter.toLowerCase()) ||
    v.expiryDate.toLowerCase().includes(filter.toLowerCase())
  );
};

export const VaultTable: React.FC<Props> = ({ vaults }) => {
  const navigate = useNavigate();
  // const MAX_VAULTS_PER_PAGE = 5;
  // const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [filteredVaults, setFilteredVaults] = useState<VaultListItemView[]>([]);
  const [chipStatus, setChipStatus] = useState<FilterStatus>(FilterStatus.OPEN);
  const [checkedItems, setCheckedItems] = useState([false, false, false]);

  // const pageCount = Math.ceil(filteredVaults.length / MAX_VAULTS_PER_PAGE || 1);

  const data = useMemo(() => {
    const vaultsFiltered = vaults.filter((v) => {
      if (chipStatus === FilterStatus.OPEN || !checkedItems.includes(true)) {
        return !v.expired && !v.paused && getVaultSearchInfo(v, filter);
      }
      if (chipStatus === FilterStatus.PAUSED) {
        return v.paused && getVaultSearchInfo(v, filter);
      }
      return v.expired && getVaultSearchInfo(v, filter);
    });
    setFilteredVaults(vaultsFiltered);
    return (
      vaultsFiltered
        // .slice(
        //   (currentPage - 1) * MAX_VAULTS_PER_PAGE,
        //   Math.min(currentPage * MAX_VAULTS_PER_PAGE, filteredVaults.length)
        // )
        .map((vault: VaultListItemView) => ({
          vaultName: vault.name,
          underwriting: vault.underwritingToken,
          network: vault.network,
          rewards: vault.apy,
          vaultCapacity: vault.capacity,
          expiry: vault.expiryDate,
          vaultID: vault.vaultID,
        }))
    );
  }, [vaults, filter, chipStatus, checkedItems]);

  // const setPage = (selectedPage: number) => {
  //   if (selectedPage < 1 || selectedPage > pageCount) return;
  //   setCurrentPage(selectedPage);
  // };

  const columns = useMemo(
    () => [
      {
        Header: 'VAULT NAME',
        accessor: 'vaultName',
      },
      {
        Header: 'UNDERWRITING TOKEN',
        accessor: 'underwriting',
        Cell: Token,
      },
      {
        Header: 'NETWORK',
        accessor: 'network',
      },
      {
        Header: 'REWARDS',
        accessor: 'rewards',
      },
      {
        Header: 'VAULT CAPACITY',
        accessor: 'vaultCapacity',
      },
      {
        Header: 'EXPIRY DATE',
        accessor: 'expiry',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns,
      data,
    });

  return (
    <>
      <SGrid>
        <SearchBar onInputChange={setFilter} />
        <Stack direction="row" marginBottom="0.8rem" marginRight="auto">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
              _active={{ filter: 'brightness(85%)' }}
              _focus={{ outline: 'none', boxShadow: 'none' }}
              px="var(--button-px)"
              py="var(--button-py)"
              textColor="white"
              background="var(--components-primary-purple)"
              border="0.5px solid #7e9bd1"
              fontSize="var(--p-font-size)"
            >
              Filter vaults
            </MenuButton>
            <MenuList bg="var(--onboarding-color)" border="transparent">
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.OPEN)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[0]}
                  onChange={(e) =>
                    setCheckedItems([e.target.checked, false, false])
                  }
                >
                  Open
                </Checkbox>
              </MenuItem>
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.PAUSED)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[1]}
                  onChange={(e) =>
                    setCheckedItems([false, e.target.checked, false])
                  }
                >
                  Paused
                </Checkbox>
              </MenuItem>
              <MenuItem
                onClick={() => setChipStatus(FilterStatus.EXPIRED)}
                background='transparent'
                _hover={{
                  background: 'transparent',
                  textDecoration: 'underline',
                }}
                color="white"
              >
                <Checkbox
                  isChecked={checkedItems[2]}
                  onChange={(e) =>
                    setCheckedItems([false, false, e.target.checked])
                  }
                >
                  Expired
                </Checkbox>
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </SGrid>
      <>
        <Box overflowX="auto">
          <STable variant="unstyled" {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => {
                    if (index === 2 || index === 4) {
                      return (
                        <React.Fragment key={index}>
                          <STh
                            {...column.getHeaderProps()}
                            textAlign={index === 4 ? 'right' : 'left'}
                          >
                            {column.render('Header')}
                          </STh>
                          <STh />
                        </React.Fragment>
                      );
                    }
                    return (
                      <STh
                        {...column.getHeaderProps()}
                        textAlign={index === 3 ? 'right' : 'left'}
                        key={index}
                      >
                        {column.render('Header')}
                      </STh>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <STr
                    {...row.getRowProps()}
                    onClick={() => {
                      navigate(
                        SubseaRoutes.vault(row.original.network, row.original.vaultID)
                      );
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      if (index === 2 || index === 4) {
                        return (
                          <React.Fragment key={index}>
                            <STd
                              {...cell.getCellProps()}
                              textAlign={index === 4 ? 'right' : 'left'}
                            >
                              {cell.render('Cell')}
                            </STd>
                            <STd />
                          </React.Fragment>
                        );
                      }
                      return (
                        <STd
                          {...cell.getCellProps()}
                          textAlign={index === 3 ? 'right' : 'left'}
                          key={index}
                          borderLeft={
                            index === 0
                              ? '1px solid var(--border-purple)'
                              : 'none'
                          }
                          borderRight={
                            index === 5
                              ? '1px solid var(--border-purple)'
                              : 'none'
                          }
                        >
                          {cell.render('Cell')}
                        </STd>
                      );
                    })}
                  </STr>
                );
              })}
            </Tbody>
          </STable>
          {filteredVaults.length === 0 && (
            <SearchNotFound
              message={
                filter === ''
                  ? `There are currently no ${chipStatus} vaults.`
                  : 'Sorry, we weren’t able to find results for your search.'
              }
            />
          )}
        </Box>
        {/* <Center>
            <PageSelector>
              <PageMover
                disabled={currentPage === 1}
                onClick={() => setPage(currentPage - 1)}
              >
                <ArrowBackIcon boxSize={6} />
              </PageMover>
              Page&nbsp;
              {currentPage} of&nbsp;{pageCount}
              <PageMover
                disabled={currentPage === pageCount}
                onClick={() => setPage(currentPage + 1)}
              >
                <ArrowForwardIcon boxSize={6} />
              </PageMover>
            </PageSelector>
          </Center> */}
      </>
    </>
  );
};
