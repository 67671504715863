import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #281d45;
  border-radius: 6px;
  max-width: 75%;
  width: 33rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 960px) {
    max-width: 100%;
    width: 100%;
  }
`;

const InputBox = styled(Input)`
  border-radius: 0;
  height: 70px;
`;

export const SearchBar: React.FC<{
  onInputChange: (e: string) => void;
}> = ({ onInputChange }) => {
  let placeholderText = '';
  const bpPlaceholderText = 'Search for an asset to protect';
  const ppPlaceholderText = 'Search for a vault to invest in';

  const { pathname } = useLocation();
  if (pathname === '/buy-protection') {
    placeholderText = bpPlaceholderText;
  } else {
    placeholderText = ppPlaceholderText;
  }

  return (
    <Container>
      <InputGroup borderRadius="6px" h="60px">
        <InputLeftElement zIndex={1} pointerEvents="none" fontSize="20px">
          <SearchIcon
            color="var(--text-secondary-gray)"
            style={{
              marginTop: '20px',
              marginLeft: '20px',
            }}
          />
        </InputLeftElement>
        <InputBox
          placeholder={placeholderText}
          _placeholder={{ opacity: '0.7', color: 'var(--text-primary-white)' }}
          h="60px !important"
          border="0px !important"
          borderRadius="6px !important"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e.target.value)
          }
          pl="60px !important"
          focusBorderColor="none"
          color="var(--text-secondary-gray)"
        />
      </InputGroup>
    </Container>
  );
};
