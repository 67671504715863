import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ToolTip } from '../ToolTip/ToolTip';
import { VaultDetails } from '../../constants/constants';
import { useVaultPageContext } from '../../contexts/VaultPage';
import {
  calculateLeverage,
  mapRawAmountToUi,
  sigFigFormat,
} from '../../utils/helpers';
import { bannerDetailsFallback } from '../../utils/cmsfallbacks';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 5rem;
  grid-row-gap: 2rem;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 0rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

export const VaultDetailsGrid: React.FC = () => {
  const vaultCapText = 'VAULT CAPACITY';
  const riskAversionText = 'RISK AVERSION';
  const leverageText = 'LEVERAGE';

  const vaultPageData = useVaultPageContext();
  const vault = vaultPageData.map((d) => d.vault);

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          UNDERWRITING ASSET
          <ToolTip tooltipText={VaultDetails.UWA} />
        </Text>
        <SmallText>
          {vault
            .map((v) => v.onchain.base.config.underwritingToken.name)
            .getOrElse(() => '')}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {vaultCapText}
          <ToolTip tooltipText={VaultDetails.VC} />
        </Text>
        <SmallText>
          {vault
            .map((v) => {
              const undrSymbol = v.onchain.base.config.underwritingToken.symbol;
              const undrDecimals =
                v.onchain.base.config.underwritingToken.decimals;
              const capacity = v.onchain.base.state.allocationVector[0];

              return `${sigFigFormat(
                mapRawAmountToUi(capacity.toString(), undrDecimals)
              )} ${undrSymbol}`;
            })
            .getOrElse(String)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {riskAversionText}
          <ToolTip tooltipText={VaultDetails.RA} />
        </Text>
        <SmallText>
          {vault
            .flatMap((v) => v.cms)
            .map((v) => v.riskAversion.toString())
            .getOrElse(bannerDetailsFallback)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          NETWORK
          <ToolTip tooltipText={VaultDetails.NET} />
        </Text>
        <SmallText>
          {vault.map((v) => v.onchain.chain.name).getOrElse(String)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {leverageText}
          <ToolTip tooltipText={VaultDetails.LVG} />
        </Text>
        <SmallText>
          {vault
            .map((v) => {
              return `${calculateLeverage(v.onchain.base).toPrecision(2)}x`;
            })
            .getOrElse(String)}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          EXPIRATION DATE
          <ToolTip tooltipText={VaultDetails.EXP} />
        </Text>
        <SmallText>
          {vault
            .map((v) => {
              const expirationMs = v.onchain.base.config.expiration * 1e3;
              const expirationDate = new Date(expirationMs);
              return `${moment(expirationDate.toUTCString()).format(
                'MMM. D, YYYY'
              )}`;
            })
            .getOrElse(String)}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
