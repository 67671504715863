import React from 'react';
import styled from 'styled-components';
import { useNavigate} from 'react-router-dom';
import arrow from '../../assets/arrow.svg';
import { usePoolPageContext } from '../../contexts/PoolPage';
import { SubseaRoutes } from '../../utils/routes';
import { UnderwriteInfo } from './UnderwriteInfo';
import { VaultDetailsGrid } from './VaultDetailsGrid';
import { PoolDetailsGrid } from './PoolDetailsGrid';
import { ProtectionInfo } from './ProtectionInfo';

const FlexBox = styled.div`
  display: flex;
  background: var(--banner-gradient-purple);
  border-radius: var(--card-radius);
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 1rem;
  align-items: center;
  padding: 1.5rem;

  @media only screen and (max-width: 1473px) {
    flex-direction: column;
    align-items: normal;
  }
`;

const Arrow = styled.img`
  margin-right: 10px;
`;

const RoutesFlex = styled.div`
  display: flex;
`;

const SDiv = styled.div`
  display: flex;
  color: white;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    filter: brightness(65%);
  }
`;

interface Props {
  type: string;
}

export const Banner: React.FC<Props> = ({ type }) => {
  const poolPageData = usePoolPageContext();
  const navigate = useNavigate();

  return (
    <>
      <RoutesFlex>
        <SDiv
          onClick={() =>
            type === 'protection'
              ? navigate('/buy-protection')
              : navigate('/provide-protection')
          }
        >
          <Arrow src={arrow} alt="arrow icon" />
          <p>{type === 'protection' ? 'Pools' : 'Vaults'}</p>
        </SDiv>
        {type === 'protection' && (
          <SDiv
            onClick={() =>
              navigate(
                poolPageData
                  .map((d) =>
                    SubseaRoutes.vault(
                      d.pool.onchain.vault.chain.name,
                      d.pool.onchain.vault.addr
                    )
                  )
                  .getOrElse(String)
              )
            }
            style={{ marginLeft: 'auto' }}
          >
            <p>Visit Parent Vault</p>
            <Arrow
              src={arrow}
              alt="arrow icon"
              style={{
                marginRight: '0px',
                marginLeft: '10px',
                transform: 'rotate(180deg)',
              }}
            />
          </SDiv>
        )}
      </RoutesFlex>
      <FlexBox>
        <div>
          {type === 'protection' ? <ProtectionInfo /> : <UnderwriteInfo />}
        </div>
        {type === 'protection' ? <PoolDetailsGrid /> : <VaultDetailsGrid />}
      </FlexBox>
    </>
  );
};
