import { Address } from '@risk-harbor/subsea-sdk';
import { utils } from 'ethers';

export function getAddressFromString(addressString: string): Address {
  // Validate the address string and return the checksum version
  return utils.getAddress(addressString) as Address;
}

export function equalAddrs(addr1: string, addr2: string) {
  return getAddressFromString(addr1) === getAddressFromString(addr2);
}
