import React, { useMemo } from 'react';
import { Progress } from '@chakra-ui/react';
import styled from 'styled-components';
import { usePoolPageContext } from '../../contexts/PoolPage';
import { Maybe } from '../../utils/maybe';
import { Transformer } from '../../utils/transformer';
import {
  decimalFormat,
  mapRawAmountToUi,
} from '../../utils/helpers';
import { bannerDetailsFallback } from '../../utils/cmsfallbacks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 1rem 0.5rem 1rem;
  row-gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 1473px) {
    margin-bottom: 0rem;
    margin-left: 0rem;
    flex-direction: row;
    align-items: center;
    row-gap: 0rem;
  }

  @media only screen and (max-width: 1045px) {
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1473px) {
    margin-right: 7rem;
  }

  @media only screen and (max-width: 1045px) {
    margin-right: auto;
    align-self: left;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressBar = styled(Progress)`
  width: 225px;
  max-width: 350px;

  @media only screen and (max-width: 1473px) {
    width: 65%;
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: baseline;

  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-weight: 600;
  margin-right: 10px;

  @media only screen and (max-width: 375px) {
    font-size: var(--h2-font-size);
  }
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--h2-font-size);

  @media only screen and (max-width: 375px) {
    font-size: var(--p-font-size);
  }
`;

const SmallText = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

export const ProtectionInfo: React.FC = () => {
  const poolPageData = usePoolPageContext();
  const pool = useMemo(() => poolPageData.map((d) => d.pool), [poolPageData]);
  // TODO(zak) | TODO(jason): Move this to a util function
  const utilBps = useMemo(() => {
    const capacity: Maybe<bigint> = pool.flatMap((p) => {
      const cap = p.onchain.vault.base.state.allocationVector[0];

      return cap > 0 ? Maybe.some(cap) : Maybe.none();
    });

    const utilized = pool.map(
      (p) => p.onchain.vault.base.state.allocationVector[p.onchain.id]
    );

    return capacity
      .zip(utilized)
      .map(([cap, util]) => (util * BigInt(1e4)) / cap);
  }, [pool]);

  const protectedToken = pool
    .map((p) => p.onchain.config.remoteProtectedToken.symbol)
    .getOrElse(String);

  return (
    <Container>
      <SubContainer>
        <NameContainer style={{ flexDirection: 'row' }}>
          <LargeText>
            {protectedToken}
          </LargeText>
          <Text>
            {pool
              .map((p) => p.onchain.vault.base.config.underwritingToken.symbol)
              .getOrElse(String)}
          </Text>
        </NameContainer>
        <SmallText>
          {pool
            .flatMap((p) => p.cms.map((d) => d.protocolName))
            .getOrElse(bannerDetailsFallback)}
        </SmallText>
      </SubContainer>
      <ProgressContainer>
        <ProgressBar
          size="xs"
          transition="all 0s"
          borderRadius="20px"
          value={utilBps
            .map((u) => Number(u.toString()) / 1e2)
            .getOrElse(Number)}
          isAnimated
          mb={1}
        />
        <SmallText>
          {pool
            .map((p) => {
              const protToken = p.onchain.config.remoteProtectedToken;
              const [capProt, utilProt] = [0, p.onchain.id]
                .map((i) => p.onchain.vault.base.state.allocationVector[i])
                .map(Transformer.undrToProt(p.onchain.config.payoutRatio));

              const remProt = capProt - utilProt;

              return `${decimalFormat(
                      mapRawAmountToUi(remProt.toString(), protToken.decimals)
                    )
              } ${
                protToken.symbol
              } remaining`;
            })
            .getOrElse(String)}
        </SmallText>
      </ProgressContainer>
    </Container>
  );
};
