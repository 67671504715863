import { ethers } from "ethers";
import { Address } from "wagmi";
import { erc20Addrs } from "./mainnetCrossChainMessenger";
import { erc20ABI } from "../constants/abis/erc20";
import { MAINNET_KARAK_RPC } from "../contexts";

export async function getUserKarakBalances(address: Address) {
  const l2Signer = new ethers.providers.JsonRpcProvider(MAINNET_KARAK_RPC)
    .getSigner(address);
  const l2ERC20 = new ethers.Contract(erc20Addrs.l2, erc20ABI, l2Signer);
  
  const ethBalance = (await l2Signer.getBalance()).toString();
  const usdcBalance = (await l2ERC20.balanceOf(address)).toString() as string;

  return { ethBalance, usdcBalance };
}
