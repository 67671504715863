import { Fraction } from '@risk-harbor/subsea-sdk';
import Decimal from 'decimal.js';

interface AMMConfig {
  expectedXVector: Fraction[];
  varCovarMatrixFlattened: Fraction[];
  lambda: Fraction;
}

function fractionToDecimal(ratio: Fraction): Decimal {
  return new Decimal(ratio.numerator.toString()).div(
    new Decimal(ratio.denominator.toString())
  );
}

export function calculatePoolRiskScores(
  ammConfig: AMMConfig,
  ttl: number
): Decimal[] {
  const fractionExpectedXVector = ammConfig.expectedXVector;
  const expectedLossVector = fractionExpectedXVector.map(fractionToDecimal);
  // Start at index 1 since index 0 is always 1 for expected loss vector
  for (let i = 1; i < expectedLossVector.length; i += 1) {
    expectedLossVector[i] = expectedLossVector[i].mul(new Decimal(1 / ttl));
  }
  return expectedLossVector;
}
