import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from 'react';
  import { Logger } from '../utils/logger';
  
  export type LoadingFlags = {
    appLoaded: boolean;
    onchainDataLoaded: boolean;
    cmsDataLoaded: boolean;
  };
  
  export type LoadingSetters = {
    setOnchainDataLoaded(): void;
    setCmsDataLoaded(): void;
  };
  
  export type LoadingContextValue = LoadingFlags & LoadingSetters;
  
  export const LoadingContext = createContext<LoadingContextValue>({
    appLoaded: false,
    onchainDataLoaded: false,
    cmsDataLoaded: false,
    setOnchainDataLoaded() {},
    setCmsDataLoaded() {},
  });
  
  export function useLoadingContext() {
    return useContext(LoadingContext);
  }
  
  export const LoadingProvider: React.FC<any> = ({ children }) => {
    const [appLoaded, setAppLoadedState] = useState(false);
    const [onchainDataLoaded, setOnchainDataLoadedState] = useState(false);
    const [cmsDataLoaded, setCmsDataLoadedState] = useState(false);
  
    const setOnchainDataLoaded = useRef(() => {
      setOnchainDataLoadedState(true);
    });
  
    const setCmsDataLoaded = useRef(() => {
      setCmsDataLoadedState(true);
    });
  
    useEffect(() => {
      if (onchainDataLoaded) {
        setAppLoadedState(true);
      }
    }, [onchainDataLoaded]);
  
    useEffect(() => {
      if (onchainDataLoaded) {
        Logger.info('[LOADING] On-chain data loaded');
      }
    }, [onchainDataLoaded]);
  
    useEffect(() => {
      if (cmsDataLoaded) {
        Logger.info('[LOADING] CMS data loaded');
      }
    }, [cmsDataLoaded]);
  
    useEffect(() => {
      if (appLoaded) {
        Logger.info('[LOADING] App loaded');
      }
    }, [appLoaded]);
  
    const value: LoadingContextValue = useMemo(
      () => ({
        appLoaded,
        onchainDataLoaded,
        cmsDataLoaded,
        setOnchainDataLoaded: setOnchainDataLoaded.current,
        setCmsDataLoaded: setCmsDataLoaded.current,
      }),
      [appLoaded, cmsDataLoaded, onchainDataLoaded]
    );
  
    return (
      <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
    );
  };
  