import { Address } from '@risk-harbor/subsea-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CMSPoolWithVaultAndChain, useCMSData } from './CMSData';
import {
  PoolWithVaultAndChain,
  useOnChainData,
} from './OnChainData';
import { equalAddrs, getAddressFromString } from '../utils/address';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { parseChainFromNetworkStr } from '../utils/network';
import { SubseaRoutes } from '../utils/routes';
import { Chain } from 'wagmi';

export type PoolPageContextValue = {
  pool: {
    onchain: PoolWithVaultAndChain;
    cms: Maybe<CMSPoolWithVaultAndChain>;
  };
};

export const PoolPageContext = createContext<Maybe<PoolPageContextValue>>(
  Maybe.none()
);

function isPoolPath(path: string): boolean {
  return SubseaRoutes.poolBaseRegex().test(path);
}

function extractAddress(
  path: string
): Maybe<{ chain: Chain; vaultAddr: Address; poolId: number }> {
  const match = SubseaRoutes.poolExactRegex().exec(path);
  if (match === null || match[0].length < 3) {
    return Maybe.none();
  }

  try {
    return Maybe.some({
      chain: parseChainFromNetworkStr(match[1]),
      vaultAddr: getAddressFromString(match[2]),
      poolId: Number(match[3]),
    });
  } catch (err) {
    Logger.error(err, `Error occurred while parsing address out of ${path}`);
    return Maybe.none();
  }
}

export function usePoolPageContext() {
  return useContext(PoolPageContext);
}

export const PoolPageContextProvider: React.FC<any> = ({ children }) => {
  const onChainData = useOnChainData();
  const cmsData = useCMSData();
  const [value, setValue] = useState<Maybe<PoolPageContextValue>>(Maybe.none());

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const poolPathMatching = isPoolPath(location.pathname);

    const cleanup = () => {
      setValue(Maybe.none());
    };

    if (!poolPathMatching) {
      setValue(Maybe.none());
      return cleanup;
    }

    const vaultAndPoolId = extractAddress(location.pathname);
    const maybePool = vaultAndPoolId.flatMap(({ chain, vaultAddr, poolId }) =>
      onChainData.flatMap((d) =>
        Maybe.from(
          d.pools.find(
            (p) =>
              equalAddrs(p.vault.addr, vaultAddr) &&
              p.id === poolId &&
              p.vault.chain.id === chain.id
          )
        )
      )
    );

    if (maybePool.isNone()) {
      setValue(Maybe.none());
      navigate('/not-found');
      return cleanup;
    }

    const cmsPool = vaultAndPoolId.flatMap(({ chain, vaultAddr, poolId }) =>
      cmsData.flatMap((d) =>
        Maybe.from(
          d.pools.find(
            (p) =>
              equalAddrs(p.vault.address, vaultAddr) &&
              p.poolId === poolId &&
              p.chain.id === chain.id
          )
        )
      )
    );

    setValue(
      Maybe.from({
        pool: {
          onchain: maybePool.required(),
          cms: cmsPool,
        },
      })
    );

    return cleanup;
  }, [cmsData, navigate, location.pathname, onChainData]);

  return (
    <PoolPageContext.Provider value={value}>
      {children}
    </PoolPageContext.Provider>
  );
};
