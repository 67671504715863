import { ISubseaTxQuoter } from '@risk-harbor/subsea-sdk';
import { useMemo } from 'react';
import { Tx } from './shared';
import { TxExplorerLink } from '../components/TxExplorerLink';
import { ToastTitles } from '../constants/constants';
import { useHeartbeat, useSDK, VaultWithChain } from '../contexts';
import { useNotifications } from '../notifications';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { Result } from '../utils/result';
import { waitForTxToMine } from '../utils/transaction';
import { makeTxExplorerLink } from '../utils/txNotifications';
import { usePublicClient, useWalletClient } from 'wagmi';
import { useUserBalances } from '../contexts/UserBalances';

type WithdrawQuoter = ISubseaTxQuoter['withdraw'];

interface Withdraw extends Tx {
  quote(): Maybe<ReturnType<WithdrawQuoter>>;
}

const VAULT_NOT_LOADED_MESSAGE = 'Vault not loaded in useWithdraw';
const SDK_NOT_LOADED_MESSAGE = 'SDK not loaded in useWithdraw';
const SIGNER_NOT_LOADED_MESSAGE = 'Signer not loaded in useWithdraw';
const PROVIDER_NOT_LOADED_MESSAGE = 'Provider not loaded in useWithdraw';

export function useWithdraw(vault: Maybe<VaultWithChain>): Withdraw {
  const walletClient = useWalletClient();
  const heartbeat = useHeartbeat();
  const publicClient = usePublicClient();
  const notifications = useNotifications();
  const sdks = useSDK();
  const { refreshBalances } = useUserBalances();
  const sdk = useMemo(
    () => vault.flatMap((v) => Maybe.from(sdks?.get(v.chain))),
    [sdks, vault]
  );

  const standardSharesToCashIn = vault.flatMap((v) =>
    Maybe.from(v.base.state.underwritingPosition.standard.balance)
  );

  const quote = sdk
    .zip(vault)
    .zip(standardSharesToCashIn)
    .map(([[_sdk, _vault], _shares]) =>
      _sdk.txQuoter.withdraw({
        vault: _vault.addr,
        userShares: BigInt(_shares),
      })
    );

  return {
    quote() {
      return quote;
    },
    async perform() {
      try {
        const _sdk = sdk.required(SDK_NOT_LOADED_MESSAGE);
        const _vault = vault.required(VAULT_NOT_LOADED_MESSAGE);
        const _walletClient = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const _publicClient = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );

        const shares = BigInt(standardSharesToCashIn.getOrElse(() => '0'));
        if (shares === BigInt(0)) {
          throw new Error('Cannot withdraw 0 shares');
        }

        const tx = _sdk.txFactory.withdraw({
          vault: _vault.addr,
          userShares: shares,
        });

        const txHash = await tx.execute(_walletClient);
        const txHashLink = makeTxExplorerLink(_vault.chain, txHash);
        await waitForTxToMine(_publicClient, txHash);
        notifications.success(
          ToastTitles.Withdraw.successMsg,
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
        heartbeat.sendPulse();
        refreshBalances('karak'); // refresh user's karak balances after withdrawing from vault
        return Result.ok(txHash);
      } catch (err) {
        notifications.error(
          ToastTitles.Withdraw.errorMsg,
          'Check console for errors'
        );
        Logger.error('Withdraw error:', err);
        return Result.error(err as Error);
      }
    },
  };
}
