import React from 'react';
import { BlockchainProvider, ConfigProvider, SDKProvider, HeartbeatProvider, LoadingProvider, OnChainDataProvider } from '../contexts';
import { CMSDataProvider } from './CMSData';
import { BlockedUserProvider } from './BlockedUserContext';
import { UserBalancesProvider } from './UserBalances';

// This components sole responsibility is to wrap around all the contexts of this app in the correct order
interface Props {
  children: React.ReactNode;
}

export const ContextsWrapper: React.FC<Props> = ({ children }) => {
  return (
    <LoadingProvider>
      <ConfigProvider>
        <HeartbeatProvider>
          <CMSDataProvider>
            <BlockchainProvider>
              <SDKProvider>
                <OnChainDataProvider>
                  <BlockedUserProvider>
                    <UserBalancesProvider>
                      {children}
                    </UserBalancesProvider>
                  </BlockedUserProvider>
                </OnChainDataProvider>
              </SDKProvider>
            </BlockchainProvider>
          </CMSDataProvider>
        </HeartbeatProvider>
      </ConfigProvider>
    </LoadingProvider>
  );
};
