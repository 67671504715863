import React from "react";
import styled from "styled-components";
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    useDisclosure,
} from "@chakra-ui/react";
import { Protect } from "./Protect";
import { Claim } from "./Claim";
import { Deposit } from "./Deposit";
import { Withdraw } from "./Withdraw";
import { OnboardingModal } from "../Modals/OnboardingModal";

const Container = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-purple);
    border-radius: var(--card-radius);
    max-width: 27rem;
    width: 46%;
    margin-left: auto;
    height: fit-content !important;

    @media only screen and (max-width: 1149px) {
        margin: 1rem auto 2rem 0;
        width: 100%;
    }
`;

const CustomTab = styled(Tab)`
    color: var(--text-primary-white);
    text-align: center;
    font-weight: 600;
    width: 100%;
    border: 0.5px solid #7e9bd1;

    &:hover {
        filter: brightness(80%);
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }

    @media only screen and (max-width: 499px) {
        &:hover {
            filter: brightness(100%);
        }
    }
`;

const Line = styled.hr`
    color: var(--border-purple);
`;

interface Props {
  type: string;
}

export const OrderBox: React.FC<Props> = ({
  type,
}) => {
    const protectText = "PROTECT";
    const claimText = "FILE EVENT";
    const withdrawText = "WITHDRAW";
    const depositText = "DEPOSIT";
    const {
        onOpen: onOnboardingOpen,
        isOpen: isOnboardingOpen,
        onClose: onOnboardingClose,
    } = useDisclosure();

    return (
        <Container>
            <Tabs variant="unstyled">
                <TabList>
                    {type === "pool" ? (
                        <>
                            <CustomTab
                                fontSize="var(--h2-font-size)"
                                padding="0.75rem 0rem"
                                borderRadius="var(--card-radius) 0rem 0rem 0rem !important"
                                _selected={{
                                    bg: "var(--components-primary-purple)",
                                }}
                            >
                                {protectText}
                            </CustomTab>
                            <CustomTab
                                fontSize="var(--h2-font-size)"
                                padding="0.75rem 0rem"
                                borderRadius="0rem var(--card-radius) 0rem 0rem !important"
                                _selected={{
                                    bg: "var(--components-primary-purple)",
                                }}
                            >
                                {claimText}
                            </CustomTab>
                        </>
                    ) : (
                        <>
                            <CustomTab
                                fontSize="var(--h2-font-size)"
                                padding="0.75rem 0rem"
                                borderRadius="var(--card-radius) 0rem 0rem 0rem !important"
                                _selected={{
                                    bg: "var(--components-primary-purple)",
                                }}
                            >
                                {depositText}
                            </CustomTab>
                            <CustomTab
                                fontSize="var(--h2-font-size)"
                                padding="0.75rem 0rem"
                                borderRadius="0rem var(--card-radius) 0rem 0rem !important"
                                _selected={{
                                    bg: "var(--components-primary-purple)",
                                }}
                            >
                                {withdrawText}
                            </CustomTab>
                        </>
                    )}
                </TabList>
                <Line />
                <TabPanels>
                    <TabPanel>
                      {type === "pool" ? (
                        <Protect onConnect={onOnboardingOpen} />
                      ) : (
                        <Deposit onConnect={onOnboardingOpen} />
                      )}
                    </TabPanel>
                    <TabPanel>
                      {type === "pool" ? (
                        <Claim onConnect={onOnboardingOpen} />
                      ) : (
                        <Withdraw onConnect={onOnboardingOpen} />
                      )}
                    </TabPanel>
                    <OnboardingModal
                        isOpen={isOnboardingOpen}
                        onClose={onOnboardingClose}
                    />
                </TabPanels>
            </Tabs>
        </Container>
    );
};

OrderBox.defaultProps = {};
