import styled from 'styled-components';
import { Table, Th, Tr, Box, Thead } from '@chakra-ui/react';
import { DistressedAssetPosition } from '../../types/types';
import { PaginationHOC } from './PaginationHOC';
import { ToolTip } from '../ToolTip/ToolTip';
import { ProtectionDetails } from '../../constants/constants';
import { DAItem } from './DAItem';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

interface Props {
  items: DistressedAssetPosition[];
}

export const DATable = PaginationHOC<Props>(({ items }) => {
  return (
    <Box overflowX="auto">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>
              PROTECTED
              <ToolTip tooltipText={ProtectionDetails.PA} />
            </STh>
            <STh>
              NETWORK
              <ToolTip tooltipText={ProtectionDetails.NET} />
            </STh>
            <STh>
              PROTOCOL
              <ToolTip tooltipText={ProtectionDetails.STATUS} />
            </STh>
          </Tr>
        </Thead>
        {items.map((position: DistressedAssetPosition, index) => (
          <DAItem daItem={position} key={index} />
        ))}
      </STable>
    </Box>
  );
});
