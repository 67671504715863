import React from 'react';
import styled from 'styled-components';
import { ButtonConnect } from '../components/Header/ButtonConnect';

const FullPage = styled.div`
  height: 100vh;
`;

const Container = styled.div`
  display: flex;
  border: 1px solid var(--border-purple);
  border-radius: var(--card-radius);
  height: 13rem;
`;

const Text = styled.p`
  color: var(--text-primary-white);
  font-size: var(--h2-font-size);
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;

  @media only screen and (max-width: 499px) {
    width: 85%;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
`;

interface Props {
  onClick: () => void;
}

export const ConnectEmptyState: React.FC<Props> = ({ onClick }) => {
  return (
    <FullPage>
      <Container>
        <SubContainer>
          <Text>Please connect your wallet to proceed</Text>
          <ButtonConnect location="page" onClick={onClick} />
        </SubContainer>
      </Container>
    </FullPage>
  );
};
