import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-purple);
  border-radius: var(--card-radius);
  padding: 1.5rem;
  margin-bottom: 3rem;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
`;

const Image = styled.img`
  width: 3rem;
  height: 3rem;

  @media only screen and (max-width: 499px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h2-font-size);
  font-weight: 600;
`;

const Text = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 500;
  max-width: 80%;
  & a {
    text-decoration: underline;
  }

  @media only screen and (max-width: 1076px) {
    max-width: 100%;
  }
`;

const SDiv = styled.div`
  width: 3rem;
  height: 3rem;
  margin-right: 10px;

  @media only screen and (max-width: 499px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

interface Props {
  imageSrc: string;
  title: string;
}

export const InfoCard: React.FC<Props> = ({ imageSrc, title }) => {
  const headerText = title;

  let desc1 = '';
  let desc2 = '';
  let descText1 = desc1;
  let descText2 = desc2;

  if (title === 'Buy Protection') {
    desc1 =
      'Find the asset you need protection for by searching through the pools below.';
    desc2 = // eslint-disable-next-line max-len
      'Once you purchase protection, your assets are programatically secured against a wide spectrum of risks, exploits, and attacks. If a risk event occurs, you are able to file an event and Subsea will algorithmically verify whether the event is valid or not. If valid, you will get paid out almost instantly, or in about 30 seconds.';
    descText1 = desc1;
    descText2 = desc2;
  } else {
    desc1 =
      'Provide protection safely and earn risk-adjusted rewards by searching through the vaults below.';
    desc2 = // eslint-disable-next-line max-len
      'Leverage market-dynamic pricing paired with infinite investment and protection permutations to help manage risk exposure while maximizing rewards. Risk data is quantitatively modeled and tested to identify acute risk scenarios, enabling users to tailor asset allocation to their unique risk profiles.';
    descText1 = desc1;
    descText2 = desc2;
  }

  return (
    <Container>
      <SubContainer>
        <SDiv>
          <Image src={imageSrc} alt="shield" />
        </SDiv>
        <LargeText>{headerText}</LargeText>
      </SubContainer>
      <div>
        <Text>{descText1}</Text>
        <div style={{ marginBottom: '1rem' }} />
        <Text>{descText2}</Text>
      </div>
    </Container>
  );
};
