import { Pool, Vault } from '@risk-harbor/subsea-sdk';
import React, { useContext, useEffect, useState } from 'react';
import { useCMSData } from './CMSData';
import { useHeartbeat } from './Heartbeat';
import { useLoadingContext } from './Loading';
import { useSDK } from './SDK';
import { getAddressFromString } from '../utils/address';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { Chain, useAccount } from 'wagmi';

export type VaultWithChain = Vault & {
  chain: Chain;
};

export type PoolWithVaultAndChain = Pool & {
  vault: VaultWithChain;
};

export type OnChainDataContextValue = {
  vaults: VaultWithChain[];
  pools: PoolWithVaultAndChain[];
};

const OnChainDataContext = React.createContext<Maybe<OnChainDataContextValue>>(
  Maybe.none()
);

export function useOnChainData() {
  return useContext(OnChainDataContext);
}

export const OnChainDataProvider: React.FC<any> = ({ children }) => {
  const [value, setValue] = useState<Maybe<OnChainDataContextValue>>(
    Maybe.none()
  );

  const [loadCount, setLoadCount] = useState<bigint>(BigInt(0));
  const { pulse } = useHeartbeat();
  const sdks = useSDK();
  const account = useAccount();
  const cmsData = useCMSData();
  const { setOnchainDataLoaded, onchainDataLoaded } = useLoadingContext();

  // This pattern means eventual consistency, i.e. updates might be delayed maximum by 2 heartbeats

  useEffect(() => {
    const loadCalls: Promise<unknown>[] = [];
    sdks?.forEach((sdk) => {
      loadCalls.push(
        sdk.querier.load(account.address).then(() => {
          setLoadCount((c) => c + BigInt(1));
        })
      );
    });

    Promise.all(loadCalls).finally(() => {
      setLoadCount((c) => c + BigInt(1));
    });
  }, [account.address, pulse, sdks, setOnchainDataLoaded]);

  useEffect(() => {
    if (!sdks) return;

    const vaults: VaultWithChain[] = [];
    const pools: PoolWithVaultAndChain[] = [];

    sdks.forEach((sdk, chain) => {
      sdk.querier.vaults().forEach((vault) => {
        vaults.push({
          ...vault,
          chain,
        });

        vault.pools.forEach((pool) => {
          pools.push({
            ...pool,
            vault: {
              ...vault,
              chain,
            },
          });
        });
      });
    });

    // NOTE: This block modifies data in place (data being the pools and vaults)
    cmsData.map((d) => { // eslint-disable-line
      if (d.tokenOverrides.length === 0) return; // eslint-disable-line

      const undrToVault = new Map<string, VaultWithChain>();
      const protToPool = new Map<string, PoolWithVaultAndChain>();

      const normalizedTokenKey = (chainId: number, addr: string) =>
        `${chainId}-${getAddressFromString(addr)}`;

      vaults.forEach((v) => {
        const networkAddrKey = normalizedTokenKey(
          v.chain.id,
          v.base.config.underwritingToken.address
        );

        undrToVault.set(networkAddrKey, v);
      });

      pools.forEach((p) => {
        const networkAddrKey = normalizedTokenKey(
          // TODO: Might break assumptions that this chain ID same as vault's downstream
          p.config.remoteProtectedToken.chainId,
          p.config.remoteProtectedToken.address
        );

        protToPool.set(networkAddrKey, p);
      });

      d.tokenOverrides.forEach((tokenOverride) => {
        const networkAddrKey = normalizedTokenKey(
          tokenOverride.chain.id,
          tokenOverride.address
        );

        const vault = undrToVault.get(networkAddrKey);
        const pool = protToPool.get(networkAddrKey);

        if (vault) {
          vault.base.config.underwritingToken.symbol =
            tokenOverride.symbolOverride;
        }

        if (pool) {
          pool.config.remoteProtectedToken.symbol = tokenOverride.symbolOverride;
        }
      });
    });

    setValue(Maybe.some({ vaults, pools }));

    if (vaults.length !== 0) {
      // set onchain data loaded as soon as there's something to show
      // @drew, rn the app WILL NOT load if there's 0 vaults (and hence 0 pools). Tbh I think this is fine
      // Worst case, if it doesn't load in like 30 seconds, we can show a banner saying
      // "Looks like something is wrong, reach out to dev team" or something, up to you
      setOnchainDataLoaded();
    }
  }, [sdks, loadCount, setOnchainDataLoaded, cmsData]);

  useEffect(() => {
    if (onchainDataLoaded) {
      value.map((d) => Logger.debug('On-chain data loaded:', d));
    }
  }, [onchainDataLoaded, value]);

  return (
    <OnChainDataContext.Provider value={value}>
      {children}
    </OnChainDataContext.Provider>
  );
};
