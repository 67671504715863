import styled from 'styled-components';
import { Table, Td, Th, Tr, Box, Thead, Tbody, VStack, Badge } from '@chakra-ui/react';
import { PolicyholderPositionItem } from '../../types/types';
import { PaginationHOC } from './PaginationHOC';
import { Link, useNavigate } from 'react-router-dom';
import { ToolTip } from '../ToolTip/ToolTip';
import { PoolStatus, ProtectionDetails } from '../../constants/constants';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-purple);
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid var(--border-purple);
  border-top: 1px solid var(--border-purple);
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

interface Props {
  items: PolicyholderPositionItem[];
}

export const ProtectionTable = PaginationHOC<Props>(({ items }) => {
  const navigate = useNavigate();
  return (
    <Box overflowX="auto">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>
              PROTECTED
              <ToolTip tooltipText={ProtectionDetails.PA} />
            </STh>
            <STh />
            <STh>
              NETWORK
              <ToolTip tooltipText={ProtectionDetails.NET} />
            </STh>
            <STh />
            <STh>
              STATUS
              <ToolTip tooltipText={ProtectionDetails.STATUS} />
            </STh>
            <STh />
            <STh>
              EXPIRY DATE
              <ToolTip tooltipText={ProtectionDetails.EXP} />
            </STh>
            <STh />
          </Tr>
        </Thead>
        {items.map((position: PolicyholderPositionItem, index) => (
          <Tbody key={index}>
            <STr onClick={() => navigate(position.route)}>
              <STd borderLeft="1px solid var(--border-purple)">
                <TokenSymbol>
                  {position.protectedTokenAmount}{' '}
                  {position.protectedTokenInfo.symbol}
                </TokenSymbol>
              </STd>
              <STd />
              <STd>{position.network}</STd>
              <STd />
              <STd>
                {position.status === PoolStatus.Hacked ? (
                  <VStack alignItems="left">
                    <Text style={{ fontSize: '15px' }}>{position.status}</Text>
                    <Link to={position.route}>
                      <Badge
                        variant="solid"
                        colorScheme="red"
                        borderRadius="8px"
                        fontSize="12px"
                        _hover={{ filter: 'brightness(80%)' }}
                      >
                        FILE EVENT
                      </Badge>
                    </Link>
                  </VStack>
                ) : (
                  <>{position.status}</>
                )}
              </STd>
              <STd />
              <STd borderRight="1px solid var(--border-purple)">{position.expiry}</STd>
              {/* TODO(zak): for later, when we re-enable buyback */}
              {/* <STd borderRight="1px solid var(--border-purple)">
                <BuyBackModal
                  poolName={position.name}
                  pool={position.pool}
                  poolNetwork={position.network}
                />
              </STd> */}
            </STr>
          </Tbody>
        ))}
      </STable>
    </Box>
  );
});
