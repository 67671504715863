import React from 'react';
import styled from 'styled-components';
import {
  InputGroup,
  InputRightAddon,
  InputLeftElement,
  Button,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const OnboardingText = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const InputBox: typeof NumberInput = styled(NumberInput)`
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
  color: var(--text-primary-white);
  border-radius: 10px 0 0 10px !important;
  box-shadow: none !important;
`;

const InputField: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  text-align: right;
  border: 2px solid #281d45 !important;
  padding-right: 10px !important;
  border-radius: 10px 0 0 10px !important;
  box-shadow: none !important;
`;

const IR = styled(InputRightAddon)`
  background-color: #281d45 !important;
  border: 2px #281d45 !important;
  color: var(--text-primary-white);
  font-weight: 600;
`;

const MaxButton = styled(Button)`
  width: 3rem !important;
  height: 60% !important;
  border-radius: 100px !important;
  background: var(--components-primary-purple) !important;
  border: 0.5px solid #7e9bd1 !important;
  color: var(--text-primary-white);
  font-size: var(--p2-font-size) !important;
  padding: 0.3rem !important;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

interface Props {
  actionType: string;
  token: string;
  amount: string;
  maxAmount: string;
  onChange: (amount: string) => void;
  disabled?: boolean;
}

export const TokenAmountInput: React.FC<Props> = ({
  actionType,
  token,
  amount,
  maxAmount,
  onChange,
  disabled = false,
}) => {
  let amountText = 'Amount';
  if (actionType === 'Withdraw') {
    amountText = 'Shares';
  }
  if (actionType === 'OnboardingDeposit') {
    amountText = `Enter an amount of ${token} to deposit.`;
  }

  return (
    <Container>
      {actionType === ('OnboardingDeposit' || 'OnboardingWithdraw') ? (
        <OnboardingText>{amountText}</OnboardingText>
      ) : (
        <Text>{amountText}</Text>
      )}
      <InputGroup>
        <InputLeftElement width="3rem !important" marginLeft="8px">
          <MaxButton
            disabled={maxAmount === '' || disabled}
            onClick={() => onChange(maxAmount)}
          >
            MAX
          </MaxButton>
        </InputLeftElement>

        <InputBox
          value={amount}
          clampValueOnBlur
          isDisabled={disabled}
          onChange={(v) => {
            try {
              if (Number.isNaN(Number(v))) {
                return;
              }
            } catch {
              return;
            }

            onChange(v);
          }}
        >
          <InputField placeholder="0" />
        </InputBox>
        <IR>{token}</IR>
      </InputGroup>
    </Container>
  );
};
