const Networks: { [chainId: number]: string } = {
  1: "ethereum",
  10: "optimism",
  42161: "arbitrum",
  2410: "karak",
};

export function getTokenIcon(chainId: number, tokenAddress: string): string {
   // eslint-disable-next-line
  return `https://raw.githubusercontent.com/Risk-Harbor/assets/master/blockchains/${Networks[chainId]}/assets/${tokenAddress}/logo.png`;
}
