import { chains } from '../contexts';
import { Chain } from 'wagmi';

export function serializeChainToRouteParam(chain: Chain): string {
  return chain.name.toLowerCase();
}

export function serializeNetworkNameToRouteParam(networkName: string): string {
  return networkName.toLowerCase();
}

export function parseChainFromNetworkStr(networkStr: string): Chain {
  const chain = chains.find(
    (c: Chain) =>
      serializeChainToRouteParam(c) ===
      serializeNetworkNameToRouteParam(networkStr)
  );

  if (!chain) {
    throw new Error(
      `Network (${networkStr}) not supported in (${chains.map((c: Chain) =>
        c.name.toLowerCase()
      )}).`
    );
  }

  return chain;
}
