/* eslint-disable class-methods-use-this */
import { Fraction } from '@risk-harbor/subsea-sdk';
import React, { useContext, useEffect } from 'react';
import { SUPPORTED_LOCAL_CHAINS } from './Blockchain';
import { EnvLoader } from '../utils/env';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';
import { Address, Chain } from 'wagmi';

const ConfigContext = React.createContext<Config | null>(null);

interface Config {
  queriers: Map<number, Address>;
  heartbeatFrequencyMs: number;
  cmsBaseUrl: Maybe<string>;
  defaultSlippage: Fraction;
}

function getAddressMap(envLoader: EnvLoader, prefix: string, chains: Chain[] = SUPPORTED_LOCAL_CHAINS): Map<number, Address> {
  const addressMap = new Map<number, Address>();

  chains.forEach((chain) => {
    const envVarName = `REACT_APP_${prefix.toUpperCase()}_ADDRESS_${chain.name === 'KarakTestnet' ? 'KARAK_TESTNET' : chain.name.toUpperCase()}`;
    const address = envLoader.loadAddress(envVarName);
    addressMap.set(chain.id, address);
  });

  return addressMap;
}

function getConfig(): Config {
  const envLoader = new EnvLoader(process.env);

  try {
    const querierAddrMap = getAddressMap(envLoader, 'QUERIER');
    const heartbeatFrequencyMs = envLoader.loadNumberMaybe('REACT_APP_HEARTBEAT_FREQUENCY_MS').getOrElse(() => 10_000);

    const cmsBaseUrl = envLoader.loadUrlMaybe('REACT_APP_CMS_BASE_URL');

    return {
      queriers: querierAddrMap,
      heartbeatFrequencyMs,
      cmsBaseUrl,
      defaultSlippage: {
        numerator: BigInt(1),
        denominator: BigInt(100),
      },
    };
  } catch (e) {
    Logger.error(e);
    throw e;
  }
}

export function useConfig(): Config {
  const config = useContext(ConfigContext);
  if (config === null) {
    throw new Error('Config context is not initialized');
  }

  return config;
}

export function ConfigProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const config = getConfig();

  useEffect(() => {
    Logger.info('App initialized with config:', config);
  }, [config]);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
